// src/store/actions/notificationActions.js
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION';
export const MARK_AS_READ = 'MARK_AS_READ';  // Action to mark a notification as read

export const showNotification = (message, type) => ({
  type: SHOW_NOTIFICATION,
  payload: { id: Date.now(), message, type, read: false }
});

export const clearNotification = () => ({
  type: CLEAR_NOTIFICATION,
});

// src/store/actions/notificationActions.js
export const markAsRead = (id) => ({
    type: 'MARK_AS_READ',
    payload: { id } // Send the id as an object
});

