let BASE_URL = "https://dev.skillsbridge.ai/api"; // Base URL for API
let AUTH_BASE_URL = "https://dev.skillsbridge.ai";
// const BASE_URL = "http://127.0.0.1:5000/api"; // Base URL for testing locally

if (process.env.NODE_ENV === "production") {
  BASE_URL = process.env.REACT_APP_API_BASE_URL;
  AUTH_BASE_URL = process.env.REACT_APP_AUTH_BASE_URL;
}

export const API_ENDPOINTS = {
  ROLES: `${BASE_URL}/roles`,
  UPDATE_COMMENT: `${BASE_URL}/update_comment`,
  VERSIONS: `${BASE_URL}/versions`,
  SB_JOB_DESCRIPTION: `${BASE_URL}/sb_job_description`,
  ORIGINAL_JOB_DESCRIPTION: `${BASE_URL}/original_job_description`,
  AUTOMATION_POTENTIAL: `${BASE_URL}/automation_potential`,
  COMPARE_DATA: `${BASE_URL}/compare_data`,
  ERROR_MESSAGE: `${BASE_URL}/error_message`,
  UPLOAD: `${BASE_URL}/upload`,
  UPLOAD_INSERT: `${BASE_URL}/upload_insert`,
  ANALYZE_JD: `${BASE_URL}/analyze_jd`,
  GET_UPLOADED_FILE: `${BASE_URL}/get_uploaded_file`,
  READ_FROM_BIGQUERY: `${BASE_URL}/bq/read`,
  UPDATE_BIGQUERY: `${BASE_URL}/bq/update`,
  CREATE_VERSION: `${BASE_URL}/create_version`,
  INTERVIEW_QUESTIONS: `${BASE_URL}/interview_questions`,
  CREATE_JD: `${BASE_URL}/create_jd`,
  CREATE_JD_INSERT: `${BASE_URL}/create_jd_insert`,

  AUTHENICATION: `${AUTH_BASE_URL}/auth/api/auth/login`,
  LOGOUT: `${AUTH_BASE_URL}/auth/api/auth/logout`,
  PROTECTED: `${BASE_URL}/user/view`,
  ENTITLEMENT: `${AUTH_BASE_URL}/auth/api/role/entitlements`,
  REGISTER_TEMP_USER: `${AUTH_BASE_URL}/auth/api/user/register-temp-user`,
  CREATE_NEW_USER: `${AUTH_BASE_URL}/auth/api/user/create-new-user `,
  FORGOT_PASSWORD: `${AUTH_BASE_URL}/auth/api/user/forgot-password`,
  SET_PASSWORD: `${AUTH_BASE_URL}/auth/api/user/set-password`,
  FETCH_ROLES: `${BASE_URL}/roles`,
  FETCH_OG_JD_EVAL: `${BASE_URL}/original_jd_eval_score`,
  FETCH_SB_JD_EVAL: `${BASE_URL}/generated_jd_eval_score`,
  FETCH_ORG_METADATA: `${AUTH_BASE_URL}/auth/api/organization/metadata`,
  SAFETYCHECK: `${BASE_URL}/safety/check`,
  GET_ORG_JDCOUNT: `${BASE_URL}/org_jd_count`

  // Add more endpoints here as needed
};
