import React from "react";
import SB_LEARNING_CATALYST from "../../Assets/Images/sb_dashboard.png";

const SbLearningCatalyst= () => {

  return (
    <div className="skills-Bridger">
      <img src={SB_LEARNING_CATALYST} width={"100%"}></img>
      </div>
  );
};

export default SbLearningCatalyst;