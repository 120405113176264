// src/store/reducers/notificationReducer.js
import { SHOW_NOTIFICATION, CLEAR_NOTIFICATION } from '../actions/notificationActions';

const initialState = {
  notifications: [],  // Array to store notifications
  unreadCount: 0,     // Count of unread notifications
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_NOTIFICATION:
      const newNotifications = [...state.notifications, action.payload];
      return {
        ...state,
        notifications: newNotifications,
        unreadCount: newNotifications.filter(n => !n.read).length,  // Count unread notifications
      };
    case CLEAR_NOTIFICATION:
      return {
        ...state,
        notifications: [],
        unreadCount: 0,  // Reset count when clearing notifications
      };
    case 'MARK_AS_READ': {
      // Mark the specific notification as read
      const updatedNotifications = state.notifications.map(notification =>
        notification.id === action.payload.id ? { ...notification, read: true } : notification
      );

      // Calculate the updated unread count
      const unreadCount = updatedNotifications.filter(n => !n.read).length;
      return {
        ...state,
        notifications: updatedNotifications,
        unreadCount, // Set the updated unread count
      };
    }
    default:
      return state;
  }
};

export default notificationReducer;
