import React, { useContext, useState, useEffect, useMemo } from "react";
import Button from 'react-bootstrap/Button';
import { Form } from 'react-bootstrap';
import { createJD, createJdInsert, uploadJD, uploadJDInsert, safetyCheck } from "../../Api/apiService";
import { AuthContext } from "../../State/AuthContext";
import "./SbJdCreationDrawer.scss"
import { useDispatch } from 'react-redux';
import { showNotification } from '../../Store/actions/notificationActions';
import { Country, State, City } from 'country-state-city';

const SbJdCreationDrawer = ({ email, accessToken, isDrawerOpen, toggleDrawer, setReloadRoles, setWaitingforResponse, setLlmresponse, setUploadJdResponseId, setCreateJdResponseId }) => {

    const dispatch = useDispatch();  // Get dispatch function
    const { orgMetaData, subscriptionType } = useContext(AuthContext);

    const [jdActionSelection, setJdActionSelection] = useState('revise');// for selection of upload/create

    useEffect(() => {
        if (subscriptionType === 'free') {
            setJdActionSelection('create');
        } else if (subscriptionType === 'paid') {
            setJdActionSelection('revise');
        }
    }, [subscriptionType]); // This hook will run whenever subscriptionType changes

    const [safetyScanning, setsafetyScanning] = useState(false);
    const [loadingLocations, setloadingLocations] = useState(false);
    const [safetyCheckPassedFile, setSafetyCheckPassedFile] = useState(null);
    const [safetyCheckPassedRContext, setSafetyCheckPassedRContext] = useState(null);
    const [safetyCheckPassedBContext, setSafetyCheckPassedBContext] = useState(null);

    // State variables for form data
    const [fileName, setFileName] = useState('');
    const [roleTitle, setRoleTitle] = useState('');
    const [altRoleTitle, setAltRoleTitle] = useState('');
    const [functionDepartment, setFunctionDepartment] = useState('');
    const [businessUnit, setBusinessUnit] = useState('');
    const [reportingTo, setReportingTo] = useState('');
    const [location, setLocation] = useState('');
    const [jobGrade, setJobGrade] = useState('');
    const [roleType, setRoleType] = useState('');
    const [workEnvironment, setWorkEnvironment] = useState('');
    const [technicalCompetence, setTechnicalCompetence] = useState('');
    const [roleContext, setRoleContext] = useState('');
    const [businessContext, setBusinessContext] = useState('');
    const [file, setFile] = useState(null);

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [orgMetaDataCompany, setorgMetaDataCompany] = useState(orgMetaData.company_structure);
    const [orgName, setOrgName] = useState("");
    const [orgIndustry, setOrgIndustry] = useState("");
    const [searchInput, setSearchInput] = useState('');
    const [statesAndCities, setStatesAndCities] = useState([]);
    const [selectedlocation, setSelectedlocation] = useState(false);
    const [countryOptions, setCountryOptions] = useState([]);


    // function to manage the upload create radio change
    const handleRadioChange = (event) => {
        setJdActionSelection(event.target.value);
        removeFile();
    };

    // resetting the state variables
    setReloadRoles(false);
    setWaitingforResponse(false);
    setLlmresponse();
    setUploadJdResponseId('');
    setCreateJdResponseId('');


    // Debounce search input
    useEffect(() => {
        const timer = setTimeout(() => {
            setSearchInput(searchInput);
        }, 500);

        return () => clearTimeout(timer);
    }, [searchInput]);


    // Memoized filtered options based on search input
    const memoizedFilteredOptions = useMemo(() => {
        const query = searchInput.toLowerCase();
        return statesAndCities.filter((option) =>
            option.name.toLowerCase().includes(query)
        ).slice(0, 5000); // Limit to the first 50 results
    }, [searchInput]);

    useEffect(() => {
        setloadingLocations(true);
        const fetchData = async () => {
            try {
                // Fetch all countries
                const countries = await Country.getAllCountries();
                setCountryOptions(countries);
                const cityCountryArray = [];  // Array to store city-country combinations

                for (let i = 0; i < countries.length; i++) {
                    const country = countries[i];
                    const states = await State.getStatesOfCountry(country.isoCode);

                    for (const state of states) {
                        const cities = await City.getCitiesOfState(country.isoCode, state.isoCode);

                        cities.forEach((city) => {
                            // Combine city and country in the required format
                            const cityCountryCombination = `${city.name} - ${country.name}`;

                            // Add this combination to the array
                            cityCountryArray.push({
                                name: cityCountryCombination,
                                type: 'city - country',
                                country: country.name,
                                // state: state.name,
                                city: city.name,
                            });
                        });
                    }

                    await new Promise(resolve => setTimeout(resolve, 0)); // Break to update UI
                }

                setStatesAndCities(cityCountryArray);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setloadingLocations(false);
            }
        };
        fetchData();
    }, []);


    const handleInputChange = (e) => {
        setSearchInput(e.target.value); // Update searchInput state on each keystroke
        setSelectedlocation(false);
    };

    // Handle location option selection
    const handleSelectOption = (option) => {
        setLocation(option.name);
        setSearchInput(option.name);
        setSelectedlocation(true);
    };

    // function to set the default value by selecting the first item in meta data array
    // const findFirstValue = (key) => {
    //     const meta = orgMetaDataCompany.find(item => item[key]);

    //     // Check if meta exists and the key value is not undefined
    //     if (meta && meta[key]) {

    //         // If it's an array of objects (with 'level' and 'description')
    //         if (meta[key][0]?.level && meta[key][0]?.description && meta[key][0]?.grade_title) {
    //             return meta[key][0].level; // Return the 'level' of the first object
    //         }
    //         // If the value is an array (like 'job_grade'), return the first item
    //         if (Array.isArray(meta[key])) {
    //             return meta[key][0];
    //         }

    //         // If it's an object (like 'competency_by_role'), return the first key
    //         if (typeof meta[key] === 'object') {
    //             return Object.keys(meta[key])[0];
    //         }
    //     }

    //     return ''; // Return an empty string if the key is not found or is invalid
    // };

    useEffect(() => {
        if (orgMetaDataCompany) {
            // Set initial values based on orgMetaData or any default logic
            // setFunctionDepartment(findFirstValue("Functional_unit"));
            // setBusinessUnit(findFirstValue("business_unit"));
            // setReportingTo(findFirstValue("report_to"));

            // const jobGradeData = findFirstValue("job_grade");
            // setJobGrade(jobGradeData);

            // setRoleType(findFirstValue("role_type"));
            // setWorkEnvironment(findFirstValue("Work_Environment"));

            // const competencies = findFirstValue("competency_by_role");
            // setTechnicalCompetence(competencies);

            if (orgMetaData.org_name) {
                setOrgName(orgMetaData.org_name);
            }

            if (orgMetaData.org_industry) {
                setOrgIndustry(orgMetaData.org_industry[0]);
            }

            if (orgMetaData.business_context.industry) {
                setBusinessContext(orgMetaData.business_context.industry);
            }
        }

    }, [orgMetaDataCompany]);


    // Handle file change
    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
            setFileName(selectedFile.name);
            setsafetyScanning(true);
            setSafetyCheckPassedFile(null);
            setRoleContext('Infer from the provided JD');
            setBusinessContext('Infer from the provided JD');
            dataSafetyCheck(selectedFile);
        }
    };


    // Function to handle file removal
    const removeFile = () => {
        setFileName("");
        setsafetyScanning(false);
        setFile(null);
        setError(null);
        setSafetyCheckPassedFile(null);
        setRoleContext('');
        setBusinessContext('');
    };


    // Validate form fields
    // const validateForm = () => {
    //     // Check for missing required fields
    //     if (!roleTitle || !altRoleTitle || !businessUnit || !functionDepartment || !workEnvironment || !location || !roleType || !reportingTo || !technicalCompetence || !jobGrade) {
    //         return false; // Stop validation if any required field is missing
    //     }
    //     return true;
    // };

    // Validate form fields
    const validateForm = () => {
        // Check for missing required fields and return an object with name, id, and type
        if (!roleTitle) return { name: 'Company Specific Job / Role Title', id: 'roleTitle', type: 'field' };
        if (!altRoleTitle) return { name: 'Generic or Industry Job / Role Title', id: 'altRoleTitle', type: 'field' };
        if (!businessUnit) return { name: 'Business Unit', id: 'businessUnit', type: 'dropdown' };
        if (!functionDepartment) return { name: 'Function/Department', id: 'functionDepartment', type: 'dropdown' };
        if (!workEnvironment) return { name: 'Work Environment', id: 'workEnvironment', type: 'dropdown' };
        if (!location) return { name: 'Location', id: 'location', type: 'dropdown' };
        if (!roleType) return { name: 'Role Type', id: 'roleType', type: 'dropdown' }; // Example of dropdown
        if (!reportingTo) return { name: 'Reporting To', id: 'reportingTo', type: 'dropdown' };
        if (!technicalCompetence) return { name: 'Technical Competence', id: 'technicalCompetence', type: 'dropdown' };
        if (!jobGrade) return { name: 'Job Grade', id: 'jobGrade', type: 'dropdown' }; // Example of dropdown

        return null; // All fields are filled
    };

    // Handle form submit
    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        // // Validate form
        // if (!validateForm()) {
        //     setError('Please fill in all required fields.');
        //     return;
        // }

        // Validate form
        const missingField = validateForm();
        if (missingField) {
            const { name, id, type } = missingField;

            // Customize error message based on field type (field or dropdown)
            if (type === 'field') {
                setError(`Enter ${name}.`);
            } else if (type === 'dropdown') {
                setError(`Select ${name}.`);
            }

            // Focus the first missing field using its ID
            document.getElementById(id).focus();
            return;
        }

        // If file is not uploaded returns false, stop further validation
        if (jdActionSelection == "revise" && file === null) {
            setError('Upload an existing JD.');
            return; // Stop and return false
        }


        // Call dataSafetyCheck based on the presence of roleContext
        let dataCheckResultRole;

        if (roleContext !== "") {
            dataCheckResultRole = await dataSafetyCheck(null, roleContext, "");
        }

        let error_message = "";
        const googleSafetyLink = '<a class="google-safety-link inherit-color" href="https://safety.google/content-safety/" target="_blank" rel="noopener noreferrer">Google Safety Check</a>';

        if (dataCheckResultRole === false) {
            error_message = `Role Context did not pass ${googleSafetyLink}. Revise and Submit.`;
        }

        setError(error_message);


        // If dataSafetyCheck returns false, stop further validation
        if (dataCheckResultRole === false) {
            return; // Stop and return false
        }

        // If dataSafetyCheck returns false, stop further validation
        if (jdActionSelection == "revise" && safetyCheckPassedFile == false) {
            setError('Uploaded file did not pass the Google safety check. Revise and Submit.');
            return; // Stop and return false
        }


        // Create payload for JD creation
        const formData = new FormData();
        formData.append('role', roleTitle);
        formData.append('alt_role_title ', altRoleTitle);
        formData.append('email', email);
        formData.append('company', orgName);
        formData.append('industry', orgIndustry);
        formData.append('business_unit', businessUnit);
        formData.append('reportingTo', reportingTo);
        formData.append('location', location);
        formData.append('function', functionDepartment);
        formData.append('level', jobGrade);
        formData.append('role_type', roleType);
        formData.append('work_environment', workEnvironment);
        formData.append('technical_competence', technicalCompetence);
        formData.append('business_context', businessContext);

        if (!safetyCheckPassedRContext) {
            formData.append('role_context', roleContext);
        }


        if (file && jdActionSelection == "revise" && safetyCheckPassedFile === true) {

            // If file exists, append it to the formData
            formData.append('file', file);

            setLoading(true);
            setWaitingforResponse(true);

            try {
                const uploadResponse = await uploadJDInsert(formData, accessToken);

                if (uploadResponse && uploadResponse.job_id) {
                    setLoading(false);
                    setWaitingforResponse(true);
                    toggleDrawer();
                    setReloadRoles(true);
                    setUploadJdResponseId(uploadResponse.job_id);
                    setCreateJdResponseId("");
                    await uploadJdNextCall(uploadResponse.job_id); // Call the next function with job_id

                } else {
                    setLoading(false);
                    setError('Error uploading JD.');
                    dispatch(showNotification('Error uploading JD.', 'error'));
                    setWaitingforResponse(false);
                    setLlmresponse(false);
                    toggleDrawer();
                    setReloadRoles(false);
                }
            } catch (error) {
                console.error('Error uploading file:', error);
                setLoading(false);
                setError('Error uploading JD.');
                dispatch(showNotification('Error uploading JD.', 'error'));
                setWaitingforResponse(false);
                setLlmresponse(false);
                toggleDrawer();
                setReloadRoles(false);
            }
        } else {

            setLoading(true);
            setWaitingforResponse(true);

            try {
                const jdResponse = await createJdInsert(formData, accessToken);

                if (jdResponse && jdResponse.job_id) {
                    setLoading(false);
                    setWaitingforResponse(true);
                    toggleDrawer();
                    setReloadRoles(true);
                    setCreateJdResponseId(jdResponse.job_id);
                    setUploadJdResponseId("");
                    await createJdNextCall(jdResponse.job_id);
                } else {
                    setLoading(false);
                    setError('Could not create JD. Please retry.');
                    dispatch(showNotification('Could not create JD. Please retry.', 'error'));
                    setWaitingforResponse(false);
                    setLlmresponse(false);
                    toggleDrawer();
                    setReloadRoles(false);
                }
            } catch (error) {
                console.error('Failed to create JD:', error);
                setLoading(false);
                setError('Failed to create JD.');
                dispatch(showNotification('Could not create JD. Please retry.', 'error'));
                setWaitingforResponse(false);
                setLlmresponse(false);
                toggleDrawer();
                setReloadRoles(false);
            }
        }

    };


    // Function to check data safety
    const dataSafetyCheck = async (selectedFile = null, enteredRoleContext = "", enteredBusinessContext = "") => {

        setError(""); // Reset error before each fetch

        if (selectedFile !== null) {
            setsafetyScanning(true);
        }
        if (enteredRoleContext === "" && enteredBusinessContext === "" && selectedFile === null) {
            return false;
        }

        if ((enteredRoleContext !== "" || enteredBusinessContext !== "") && selectedFile === null) {
            setLoading(true);
        }

        let passedValue = "";

        const formData = new FormData();
        formData.append('email', email);

        if (enteredRoleContext !== "") {
            formData.append('role_context', enteredRoleContext);
            passedValue = "role_context";
            setSafetyCheckPassedRContext(false);
        }


        if (enteredBusinessContext !== "") {
            formData.append('business_context', enteredBusinessContext);
            passedValue = "business_context";
            setSafetyCheckPassedBContext(false);
        }

        if (selectedFile !== null) {
            formData.append('file', selectedFile);
            passedValue = "file";
            setSafetyCheckPassedFile(false)
        }

        try {
            const safetyCheckResponse = await safetyCheck(formData, accessToken);
            if (safetyCheckResponse && safetyCheckResponse.is_safe === true) {
                setsafetyScanning(false);
                setLoading(false);
                if (passedValue == "file") {
                    setSafetyCheckPassedFile(true);
                }
                if (passedValue == "role_context") {
                    setSafetyCheckPassedRContext(true);
                }
                if (passedValue == "business_context") {
                    setSafetyCheckPassedBContext(true);
                }
                return true;
            } else {
                setsafetyScanning(false);
                setLoading(false);
                if (passedValue == "file") {
                    setSafetyCheckPassedFile(false);
                }

                if (passedValue == "role_context") {
                    setSafetyCheckPassedRContext(false);
                }

                if (passedValue == "business_context") {
                    setSafetyCheckPassedBContext(false);
                }
                return false;
            }
        } catch (error) {
            console.error('Error validating safety check:', error);
            setsafetyScanning(false);
            setLoading(false);
            if (passedValue == "file") {
                setSafetyCheckPassedFile(false);
            }
            if (passedValue == "role_context") {
                setSafetyCheckPassedRContext(false);
            }
            if (passedValue == "business_context") {
                setSafetyCheckPassedBContext(false);
            }
            setError('Could not run safety checks. Please retry.');
            return false;
        }
    };


    // Function to call revise jd next call
    const uploadJdNextCall = async (job_id) => {
        setLoading(true);
        setError(null); // Reset error before each fetch

        // Create payload for JD creation
        const payload = {
            "email": email,
            "job_id": job_id
        };

        try {
            const uploadResponse = await uploadJD(JSON.stringify(payload), accessToken);
            if (uploadResponse) {
                setLoading(false);
                dispatch(showNotification('JD revised successfully', 'success'));
                setWaitingforResponse(false);
                setLlmresponse(true);
            } else {
                setLoading(false);
                setError('Error revising JD.');
                dispatch(showNotification('Error revising JD.', 'error'));
                setWaitingforResponse(false);
                setLlmresponse(false);
            }
        } catch (error) {
            console.error('Error revising file:', error);
            setLoading(false);
            setError('Error revising JD.');
            dispatch(showNotification('Error revising JD.', 'error'));
            setWaitingforResponse(false);
            setLlmresponse(false);
        }
    };

    // Function to call create jd
    const createJdNextCall = async (job_id) => {
        setLoading(true);
        setError(null); // Reset error before each fetch

        const payload = {
            "email": email,
            "job_id": job_id
        };

        try {
            const createResponse = await createJD(JSON.stringify(payload), accessToken);
            if (createResponse) {
                setLoading(false);
                dispatch(showNotification('JD created successfully', 'success'));
                setWaitingforResponse(false);
                setLlmresponse(true);
            } else {
                setLoading(false);
                setError('Could not create JD. Please retry.');
                dispatch(showNotification('Could not create JD. Please retry.', 'error'));
                setWaitingforResponse(false);
                setLlmresponse(false);
            }
        } catch (error) {
            console.error('Error creating file:', error);
            setLoading(false);
            setError('Could not create JD. Please retry.');
            dispatch(showNotification('Could not create JD. Please retry.', 'error'));
            setWaitingforResponse(false);
            setLlmresponse(false);
        }
    };

    return (
        <div>

            {/* Overlay that darkens the background */}
            {isDrawerOpen && <div className="overlay" onClick={toggleDrawer}></div>}

            {/* Drawer that slides in from the right */}
            <div className={`drawer ${isDrawerOpen ? 'open' : ''}`}>
                <div className="drawer-header">
                    <div className="radio-buttons custom-tabs">


                        <div
                            className={`tab-title ${jdActionSelection === 'revise' ? 'active' : ''} ${subscriptionType === "free" ? 'disabled' : ''}`}
                            onClick={subscriptionType === "free" ? null : () => handleRadioChange({ target: { value: 'revise' } })}
                            style={{ cursor: subscriptionType === "free" ? 'not-allowed' : 'pointer' }}
                        >
                            Revise JD
                        </div>
                        <div
                            className={`tab-title ${jdActionSelection === 'create' ? 'active' : ''}`}
                            onClick={() => handleRadioChange({ target: { value: 'create' } })}
                        >
                            Create JD
                        </div>
                    </div>

                    <div onClick={toggleDrawer} title="Close">
                        <svg width="24" height="28" viewBox="0 0 24 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18 6.85693L6 20.5712" stroke="#2F3437" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M6 6.85693L18 20.5712" stroke="#2F3437" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>

                    </div>
                </div>

                <div className="drawer-body">

                    <Form onSubmit={handleSubmit}>

                        <div>

                            <div className="upload-role-section d-flex w-100 mb-4">
                                {jdActionSelection === "revise" &&
                                    <Form.Group controlId="formFile" className="upload-section w-100 me-3">
                                        <div className="custom-file-upload">
                                            <input
                                                type="file"
                                                accept=".pdf,.doc,.docx"
                                                id="upload-file"
                                                onChange={handleFileChange}
                                            />
                                            {safetyScanning ? (
                                                <div className="d-flex justify-content-center align-items-center flex-column mb-3">
                                                    <span className="text-center mb-3">Scanning file...</span>
                                                    <div className="spinner-border" role="status">
                                                        <span className="sr-only"></span>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="file-upload-area">
                                                    <svg
                                                        width="36"
                                                        height="36"
                                                        viewBox="0 0 36 36"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M29.025 15.06C28.005 9.885 23.46 6 18 6C13.665 6 9.9 8.46 8.025 12.06C3.51 12.54 0 16.365 0 21C0 25.965 4.035 30 9 30H28.5C32.64 30 36 26.64 36 22.5C36 18.54 32.925 15.33 29.025 15.06ZM28.5 27H9C5.685 27 3 24.315 3 21C3 17.925 5.295 15.36 8.34 15.045L9.945 14.88L10.695 13.455C12.12 10.71 14.91 9 18 9C21.93 9 25.32 11.79 26.085 15.645L26.535 17.895L28.83 18.06C31.17 18.21 33 20.175 33 22.5C33 24.975 30.975 27 28.5 27ZM12 19.5H15.825V24H20.175V19.5H24L18 13.5L12 19.5Z"
                                                            fill="#008080"
                                                        />
                                                    </svg>
                                                    <p>Drop or <span>Upload</span> an existing JD</p>
                                                    <p className="file-info">.PDF and .DOC files up to 10MB accepted.</p>
                                                </div>
                                            )}

                                        </div>

                                        {
                                            // If a file is uploaded, check safetyCheckPassedFile
                                            !safetyScanning && fileName && safetyCheckPassedFile ? (
                                                <div className="success-message-box mt-3">
                                                    <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M0 8C0 12.42 3.58 16 8 16C12.42 16 16 12.42 16 8C16 3.58 12.42 0 8 0C3.58 0 0 3.58 0 8ZM11.29 5.29C11.47 5.11 11.72 5 12 5C12.55 5 13 5.45 13 6C13 6.28 12.89 6.53 12.71 6.71L7.71 11.71C7.53 11.89 7.28 12 7 12C6.72 12 6.47 11.89 6.29 11.71L3.29 8.71C3.11 8.53 3 8.28 3 8C3 7.45 3.45 7 4 7C4.28 7 4.53 7.11 4.71 7.29L7 9.59L11.29 5.29Z" fill="#52BD94" />
                                                    </svg>
                                                    <div className="success-message-holder">
                                                        <p className="success-message">Success</p>
                                                        <p className="success-message-text">File Uploaded : {fileName}</p>
                                                    </div>

                                                </div>
                                            ) : !safetyScanning && fileName && !safetyCheckPassedFile && (
                                                <div className="error-message-box mt-3">
                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M7.99989 0.00488281C3.58265 0.00488281 0.00488281 3.58265 0.00488281 7.99989C0.00488281 12.4171 3.58265 15.9949 7.99989 15.9949C12.4171 15.9949 15.9949 12.4171 15.9949 7.99989C15.9949 3.58265 12.4171 0.00488281 7.99989 0.00488281ZM7.00051 12.9968V10.998H8.99926V12.9968H7.00051ZM7.00051 3.00301V9.99864H8.99926V3.00301H7.00051Z" fill="#D14343" />
                                                    </svg>
                                                    <div className="error-message-holder">
                                                        <p className="error-message">Error</p>
                                                        <p className="error-message-text"><a className="google-safety-link" href="https://safety.google/content-safety/" target="_blank" rel="noopener noreferrer">Google Safety Check</a> Failed : {fileName} Replace file.</p>
                                                    </div>
                                                </div>
                                            )

                                        }

                                    </Form.Group>
                                }
                                <div className="role-title-sec w-100 d-flex flex-column">
                                    <Form.Group controlId="roleTitle" className="mb-4 w-100 me-3">
                                        <Form.Label>Company Specific Job / Role Title *</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={roleTitle}
                                            onChange={(e) => setRoleTitle(e.target.value)}
                                            placeholder="Enter Company Specific Job / Role Title"
                                        />
                                    </Form.Group>

                                    <Form.Group controlId="altRoleTitle" className="w-100">
                                        <Form.Label>Generic or Industry Job / Role Title *</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={altRoleTitle}
                                            onChange={(e) => setAltRoleTitle(e.target.value)}
                                            placeholder="Enter Generic or Industry Job / Role Title"
                                        />
                                    </Form.Group>
                                </div>
                            </div>

                            <div className="d-flex w-100">
                                {/* Business Unit */}
                                <Form.Group controlId="businessUnit" className="mb-4 w-100 me-3">
                                    <Form.Label>Business Unit*</Form.Label>
                                    <Form.Select
                                        value={businessUnit}
                                        className="custom-select-drawer" // Apply custom select class
                                        onChange={(e) => setBusinessUnit(e.target.value)}
                                    >
                                        <option value="">Select Business Unit</option>

                                        {/* Check if orgMetaData is valid and contains business_unit */}
                                        {Array.isArray(orgMetaDataCompany) &&
                                            orgMetaDataCompany.find(item => item.business_unit)?.business_unit?.map((unit, index) => (
                                                <option key={index} value={unit}>{unit}</option>
                                            ))
                                        }
                                    </Form.Select>
                                </Form.Group>

                                {/* Function/Department */}
                                <Form.Group controlId="functionDepartment" className="mb-4 w-100">
                                    <Form.Label>Function / Department*</Form.Label>
                                    <Form.Select
                                        value={functionDepartment}
                                        className="custom-select-drawer" // Apply custom select class
                                        onChange={(e) => setFunctionDepartment(e.target.value)}
                                    >
                                        <option value="">Select Function / Department</option>
                                        {/* Check if orgMetaData is valid and contains Functional_unit */}
                                        {Array.isArray(orgMetaDataCompany) &&
                                            orgMetaDataCompany.find(item => item.Functional_unit)?.Functional_unit?.map((unit, index) => (
                                                <option key={index} value={unit}>{unit}</option>
                                            ))
                                        }
                                    </Form.Select>
                                </Form.Group>
                            </div>

                            <div className="d-flex w-100">
                                <Form.Group controlId="workEnvironment" className="mb-4 w-100 me-3">
                                    <Form.Label>Work Environment*</Form.Label>
                                    <Form.Select
                                        value={workEnvironment}
                                        className="custom-select-drawer" // Apply custom select class
                                        onChange={(e) => {
                                            setWorkEnvironment(e.target.value); setLocation('');
                                            const selectedEnv = e.target.value;
                                            if (selectedEnv === 'Hybrid' || selectedEnv === 'Onsite') {
                                                setSearchInput(''); // Reset search input for Remote if not Hybrid or Onsite
                                            }
                                        }}
                                    >
                                        <option value="">Select Work Environment</option>
                                        {Array.isArray(orgMetaDataCompany) &&
                                            orgMetaDataCompany.find(item => item.Work_Environment)?.Work_Environment?.map((env, index) => (
                                                <option key={index} value={env}>{env}</option>
                                            ))
                                        }
                                    </Form.Select>
                                </Form.Group>

                                {workEnvironment === 'Remote' && (
                                    <Form.Group controlId="location" className="mb-4 w-100">
                                        <Form.Label>Country*</Form.Label>
                                        <Form.Select
                                            value={location}
                                            className="custom-select-drawer" // Apply custom select class
                                            onChange={(e) => setLocation(e.target.value)}
                                            disabled={loadingLocations} // Disable while loading
                                        >
                                            <option value="">Select a country</option> {/* Default placeholder */}
                                            {countryOptions.length > 0 ? (
                                                countryOptions.map((country, index) => (
                                                    <option key={index} value={country.name}>
                                                        {country.name}
                                                    </option>
                                                ))
                                            ) : (
                                                <option>Loading countries...</option> // Show loading text
                                            )}
                                        </Form.Select>
                                    </Form.Group>
                                )}

                                {(workEnvironment === 'Hybrid' || workEnvironment === 'Onsite') && (

                                    <Form.Group controlId="location" className="mb-4 w-100">
                                        <Form.Label>City / Country*</Form.Label>

                                        <div>
                                            <div className="position-relative">
                                                <input
                                                    type="text"
                                                    id="location"
                                                    className="form-control"
                                                    placeholder="Search City or Country"
                                                    value={searchInput}
                                                    onChange={handleInputChange}
                                                />
                                                {memoizedFilteredOptions.length > 0 && selectedlocation === false && (
                                                    <ul
                                                        className="list-group position-absolute w-100"
                                                        style={{ zIndex: 10, maxHeight: '200px', overflowY: 'auto' }}
                                                    >
                                                        {memoizedFilteredOptions.map((option, index) => (
                                                            <li
                                                                key={index}
                                                                className="list-group-item"
                                                                onClick={() => handleSelectOption(option)}
                                                                style={{ cursor: 'pointer' }}
                                                            >
                                                                {option.name}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                            </div>
                                        </div>
                                    </Form.Group>
                                )}

                            </div>

                            <div className="d-flex w-100">
                                {/* Role Type */}

                                <Form.Group controlId="roleType" className="mb-4 w-100 me-3">
                                    <Form.Label>Role Type*</Form.Label>
                                    <Form.Select
                                        value={roleType}
                                        className="custom-select-drawer" // Apply custom select class
                                        onChange={(e) => setRoleType(e.target.value)}
                                    >
                                        <option value="">Select Role Type</option>
                                        {Array.isArray(orgMetaDataCompany) &&
                                            orgMetaDataCompany.find(item => item.role_type)?.role_type?.map((role, index) => (
                                                <option key={index} value={role}>{role}</option>
                                            ))
                                        }
                                    </Form.Select>
                                </Form.Group>

                                {/* Reporting To */}
                                <Form.Group controlId="reportingTo" className="mb-4 w-100">
                                    <Form.Label>Reporting To*</Form.Label>
                                    <Form.Select
                                        value={reportingTo}
                                        className="custom-select-drawer" // Apply custom select class
                                        onChange={(e) => setReportingTo(e.target.value)}
                                    >
                                        <option value="">Select Reporting To</option>
                                        {/* Check if orgMetaData is valid and contains report_to */}
                                        orgMetaDataCompany    {Array.isArray(orgMetaDataCompany) &&
                                            orgMetaDataCompany.find(item => item.report_to)?.report_to?.map((role, index) => (
                                                <option key={index} value={role}>{role}</option>
                                            ))
                                        }
                                    </Form.Select>
                                </Form.Group>

                            </div>



                            <div className="d-flex w-100">

                                {/* Technical Competence */}
                                <Form.Group controlId="technicalCompetence" className="mb-4 w-100  me-3">
                                    <Form.Label>Technical Competence*</Form.Label>
                                    <Form.Select
                                        value={technicalCompetence}
                                        className="custom-select-drawer" // Apply custom select class
                                        onChange={(e) => setTechnicalCompetence(e.target.value)}
                                    >
                                        <option value="">Select Technical Competence</option>
                                        {/* Check if orgMetaData is valid and contains competency_by_role */}
                                        {Array.isArray(orgMetaDataCompany) &&
                                            orgMetaDataCompany.find(item => item.competency_by_role)?.competency_by_role &&
                                            Object.keys(orgMetaDataCompany.find(item => item.competency_by_role)?.competency_by_role).map((key, index) => (
                                                <option key={index} value={key}>{key}</option>
                                            ))
                                        }
                                    </Form.Select>
                                </Form.Group>

                                {/* Job Grade */}
                                <Form.Group controlId="jobGrade" className="mb-4 w-100">
                                    <Form.Label>Job Grade*</Form.Label>
                                    <Form.Select
                                        value={jobGrade}
                                        className="custom-select-drawer" // Apply custom select class
                                        onChange={(e) => setJobGrade(e.target.value)}
                                    >
                                        <option value="">Select Job Grade</option>
                                        {Array.isArray(orgMetaDataCompany) &&
                                            orgMetaDataCompany.find(item => item.job_grade)?.job_grade &&
                                            (Array.isArray(orgMetaDataCompany.find(item => item.job_grade)?.job_grade) && // Check if it's an array of objects
                                                orgMetaDataCompany.find(item => item.job_grade)?.job_grade[0]?.level &&
                                                orgMetaDataCompany.find(item => item.job_grade)?.job_grade[0]?.description
                                                ?
                                                orgMetaDataCompany.find(item => item.job_grade)?.job_grade.map((item, index) => (
                                                    <option key={index} value={item.level}>
                                                        {item.level} - {item.grade_title}
                                                    </option>
                                                ))
                                                :
                                                orgMetaDataCompany.find(item => item.job_grade)?.job_grade.map((value, index) => {
                                                    return (
                                                        <option key={index} value={value}>
                                                            {value}
                                                        </option>
                                                    );
                                                })
                                            )
                                        }
                                    </Form.Select>
                                </Form.Group>

                            </div>

                            <Form.Group controlId="roleContext" className="mb-4 w-100">
                                <Form.Label>Role Context</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    value={roleContext}
                                    onChange={(e) => setRoleContext(e.target.value)}
                                    placeholder="Enter Role Context"
                                />
                            </Form.Group>


                        </div>

                        {error && <div className="error-message-box">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M7.99989 0.00488281C3.58265 0.00488281 0.00488281 3.58265 0.00488281 7.99989C0.00488281 12.4171 3.58265 15.9949 7.99989 15.9949C12.4171 15.9949 15.9949 12.4171 15.9949 7.99989C15.9949 3.58265 12.4171 0.00488281 7.99989 0.00488281ZM7.00051 12.9968V10.998H8.99926V12.9968H7.00051ZM7.00051 3.00301V9.99864H8.99926V3.00301H7.00051Z" fill="#D14343" />
                            </svg>
                            <div className="error-message-holder">
                                <p className="error-message">Error</p>
                                <p className="error-message-text" dangerouslySetInnerHTML={{ __html: error }} />
                            </div>
                        </div>}

                        <div className="d-flex w-100 justify-content-end p-2">
                            {loading ? (
                                <div className="d-flex justify-content-center mb-3">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only"></span>
                                    </div>
                                </div>
                            ) : (
                                <Button variant="primary" type="submit" disabled={(jdActionSelection === "revise" && safetyCheckPassedFile === false) || loading}>
                                    Submit
                                </Button>
                            )}
                        </div>

                    </Form>
                </div>
            </div >
        </div >
    );
}


export default SbJdCreationDrawer;