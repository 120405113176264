import React from "react";
import SB_TALENT_MOBILITY from "../../Assets/Images/sb_talent_mobility.png";

const SbTalentMobility= () => {

  return (
    <div className="skills-Bridger">
      <img src={SB_TALENT_MOBILITY} width={"100%"}></img>
      </div>
  );
};

export default SbTalentMobility;