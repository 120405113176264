import React, { useContext } from "react";
import { AuthFunctionsContext } from "../../State/AuthFunctionsContext";

const SbSignupComponent = () => {
  const {
    username,
    setUsername,
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    otp,
    setOtp,
    error,
    sb_auth_handleBlur,
    loadingAuth,
    sb_auth_handleSignupStep1,
    sb_auth_handleSignupStep2,
    toggleSignup,
    signupStep,
    companyName,
    setCompanyName,
    jobTitle,
    setJobTitle
  } = useContext(AuthFunctionsContext);


  const handleNextStep = async (e) => {
    e.preventDefault();
    // Validate and send OTP in Step 1
    if (!firstName || !lastName || !username || !companyName || !jobTitle) {
      return; // Don't go to Step 2 if validation fails
    }
    await sb_auth_handleSignupStep1(e); // Send OTP to email
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    // Validate OTP and password before signup
    if (!otp || !password || password !== confirmPassword) {
      return; // Validation fails, don't proceed
    }

    sb_auth_handleSignupStep2(e); // Final submit for signup
  };

  return (
    <>
      {signupStep === 1 ? (
        <form onSubmit={handleNextStep}>

          <div className="d-flex w-100">
            <div className="mb-3 me-3 w-100">
              <label htmlFor="firstName" className="form-label w-100 text-start">
                First Name
              </label>
              <input
                type="text"
                className="form-control"
                id="firstName"
                value={firstName}
                onBlur={sb_auth_handleBlur}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
              {/* {error.firstName && (
                <p className="text-start mt-1 error-message text-danger">{error.firstName}</p>
              )} */}
              {error.firstName && <div className="error-message-box auth-page-error-box">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M7.99989 0.00488281C3.58265 0.00488281 0.00488281 3.58265 0.00488281 7.99989C0.00488281 12.4171 3.58265 15.9949 7.99989 15.9949C12.4171 15.9949 15.9949 12.4171 15.9949 7.99989C15.9949 3.58265 12.4171 0.00488281 7.99989 0.00488281ZM7.00051 12.9968V10.998H8.99926V12.9968H7.00051ZM7.00051 3.00301V9.99864H8.99926V3.00301H7.00051Z" fill="#D14343" />
                </svg>
                <div className="error-message-holder">
                  <p className="error-message">{error.firstName}</p>
                </div>
              </div>}
            </div>

            <div className="mb-3 w-100">
              <label htmlFor="lastName" className="form-label w-100 text-start">
                Last Name
              </label>
              <input
                type="text"
                className="form-control"
                id="lastName"
                value={lastName}
                onBlur={sb_auth_handleBlur}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
              {/* {error.lastName && (
                <p className="text-start mt-1 error-message text-danger">{error.lastName}</p>
              )} */}
              {error.lastName && <div className="error-message-box auth-page-error-box">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M7.99989 0.00488281C3.58265 0.00488281 0.00488281 3.58265 0.00488281 7.99989C0.00488281 12.4171 3.58265 15.9949 7.99989 15.9949C12.4171 15.9949 15.9949 12.4171 15.9949 7.99989C15.9949 3.58265 12.4171 0.00488281 7.99989 0.00488281ZM7.00051 12.9968V10.998H8.99926V12.9968H7.00051ZM7.00051 3.00301V9.99864H8.99926V3.00301H7.00051Z" fill="#D14343" />
                </svg>
                <div className="error-message-holder">
                  <p className="error-message">{error.lastName}</p>
                </div>
              </div>}
            </div>
          </div>

          <div className="d-flex w-100">

            <div className="mb-3 me-3 w-100">
              <label htmlFor="companyName" className="form-label w-100 text-start">
                Company Name
              </label>
              <input
                type="text"
                className="form-control"
                id="companyName"
                value={companyName}
                onBlur={sb_auth_handleBlur}
                onChange={(e) => setCompanyName(e.target.value)}
                required
              />
              {/* {error.companyName && (
                <p className="text-start mt-1 error-message text-danger">{error.companyName}</p>
              )} */}
              {error.companyName && <div className="error-message-box auth-page-error-box">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M7.99989 0.00488281C3.58265 0.00488281 0.00488281 3.58265 0.00488281 7.99989C0.00488281 12.4171 3.58265 15.9949 7.99989 15.9949C12.4171 15.9949 15.9949 12.4171 15.9949 7.99989C15.9949 3.58265 12.4171 0.00488281 7.99989 0.00488281ZM7.00051 12.9968V10.998H8.99926V12.9968H7.00051ZM7.00051 3.00301V9.99864H8.99926V3.00301H7.00051Z" fill="#D14343" />
                </svg>
                <div className="error-message-holder">
                  <p className="error-message">{error.companyName}</p>
                </div>
              </div>}
            </div>

            <div className="mb-3 w-100">
              <label htmlFor="jobTitle" className="form-label w-100 text-start">
                Job Title
              </label>
              <input
                type="text"
                className="form-control"
                id="jobTitle"
                value={jobTitle}
                onBlur={sb_auth_handleBlur}
                onChange={(e) => setJobTitle(e.target.value)}
                required
              />
              {/* {error.jobTitle && (
                <p className="text-start mt-1 error-message text-danger">{error.jobTitle}</p>
              )} */}
              {error.jobTitle && <div className="error-message-box auth-page-error-box">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M7.99989 0.00488281C3.58265 0.00488281 0.00488281 3.58265 0.00488281 7.99989C0.00488281 12.4171 3.58265 15.9949 7.99989 15.9949C12.4171 15.9949 15.9949 12.4171 15.9949 7.99989C15.9949 3.58265 12.4171 0.00488281 7.99989 0.00488281ZM7.00051 12.9968V10.998H8.99926V12.9968H7.00051ZM7.00051 3.00301V9.99864H8.99926V3.00301H7.00051Z" fill="#D14343" />
                </svg>
                <div className="error-message-holder">
                  <p className="error-message">{error.jobTitle}</p>
                </div>
              </div>}
            </div>
          </div>

          <div className="mb-3">
            <label htmlFor="email" className="form-label w-100 text-start">
              Business Email
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              value={username}
              onBlur={sb_auth_handleBlur}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
            {/* {error.username && (
              <p className="text-start mt-1 error-message text-danger">{error.username}</p>
            )} */}

            {error.username && <div className="error-message-box auth-page-error-box">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M7.99989 0.00488281C3.58265 0.00488281 0.00488281 3.58265 0.00488281 7.99989C0.00488281 12.4171 3.58265 15.9949 7.99989 15.9949C12.4171 15.9949 15.9949 12.4171 15.9949 7.99989C15.9949 3.58265 12.4171 0.00488281 7.99989 0.00488281ZM7.00051 12.9968V10.998H8.99926V12.9968H7.00051ZM7.00051 3.00301V9.99864H8.99926V3.00301H7.00051Z" fill="#D14343" />
              </svg>
              <div className="error-message-holder">
                <p className="error-message">{error.username}</p>
              </div>
            </div>}


          </div>

          {error.authfail && <div className="error-message-box auth-page-error-box">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M7.99989 0.00488281C3.58265 0.00488281 0.00488281 3.58265 0.00488281 7.99989C0.00488281 12.4171 3.58265 15.9949 7.99989 15.9949C12.4171 15.9949 15.9949 12.4171 15.9949 7.99989C15.9949 3.58265 12.4171 0.00488281 7.99989 0.00488281ZM7.00051 12.9968V10.998H8.99926V12.9968H7.00051ZM7.00051 3.00301V9.99864H8.99926V3.00301H7.00051Z" fill="#D14343" />
            </svg>
            <div className="error-message-holder">
              <p className="error-message">Error</p>
              <p className="error-message-text" dangerouslySetInnerHTML={{ __html: error.authfail }} />
            </div>
          </div>}

          {/* Send OTP button */}
          {loadingAuth ? (
            <div className="d-flex justify-content-center mb-3">
              <div className="spinner-border" role="status">
                <span className="sr-only"></span>
              </div>
            </div>
          ) : (
            <button type="submit" className="btn btn-primary w-100 btn-lg mb-3 auth-submit-btn">
              Send OTP
            </button>
          )}
        </form>
      ) : (
        <form onSubmit={handleSignup}>
          <div className="mb-3">
            <label htmlFor="otp" className="form-label w-100 text-start">
              OTP
            </label>
            <input
              type="tel"
              className="form-control"
              id="otp"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              onBlur={sb_auth_handleBlur}
              maxLength={6}
              required
            />
            {/* {error.otp && <p className="text-start mt-1 error-message text-danger">{error.otp}</p>} */}

            {error.otp && <div className="error-message-box auth-page-error-box">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M7.99989 0.00488281C3.58265 0.00488281 0.00488281 3.58265 0.00488281 7.99989C0.00488281 12.4171 3.58265 15.9949 7.99989 15.9949C12.4171 15.9949 15.9949 12.4171 15.9949 7.99989C15.9949 3.58265 12.4171 0.00488281 7.99989 0.00488281ZM7.00051 12.9968V10.998H8.99926V12.9968H7.00051ZM7.00051 3.00301V9.99864H8.99926V3.00301H7.00051Z" fill="#D14343" />
              </svg>
              <div className="error-message-holder">
                <p className="error-message">{error.otp}</p>
              </div>
            </div>}

          </div>

          <div className="mb-3">
            <label htmlFor="password" className="form-label w-100 text-start">
              Password
            </label>
            <input
              type="password"
              className="form-control"
              id="password"
              value={password}
              onBlur={sb_auth_handleBlur}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            {/* {error.password && (
              <p className="text-start mt-1 error-message text-danger">{error.password}</p>
            )} */}

            {error.password && <div className="error-message-box auth-page-error-box">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M7.99989 0.00488281C3.58265 0.00488281 0.00488281 3.58265 0.00488281 7.99989C0.00488281 12.4171 3.58265 15.9949 7.99989 15.9949C12.4171 15.9949 15.9949 12.4171 15.9949 7.99989C15.9949 3.58265 12.4171 0.00488281 7.99989 0.00488281ZM7.00051 12.9968V10.998H8.99926V12.9968H7.00051ZM7.00051 3.00301V9.99864H8.99926V3.00301H7.00051Z" fill="#D14343" />
              </svg>
              <div className="error-message-holder">
                <p className="error-message">{error.password}</p>
              </div>
            </div>}

          </div>

          <div className="mb-3">
            <label htmlFor="confirmPassword" className="form-label w-100 text-start">
              Confirm Password
            </label>
            <input
              type="password"
              className="form-control"
              id="confirmPassword"
              value={confirmPassword}
              onBlur={sb_auth_handleBlur}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
            {/* {error.confirmPassword && (
              <p className="text-start mt-1 error-message text-danger">{error.confirmPassword}</p>
            )} */}
            {error.confirmPassword && <div className="error-message-box auth-page-error-box">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M7.99989 0.00488281C3.58265 0.00488281 0.00488281 3.58265 0.00488281 7.99989C0.00488281 12.4171 3.58265 15.9949 7.99989 15.9949C12.4171 15.9949 15.9949 12.4171 15.9949 7.99989C15.9949 3.58265 12.4171 0.00488281 7.99989 0.00488281ZM7.00051 12.9968V10.998H8.99926V12.9968H7.00051ZM7.00051 3.00301V9.99864H8.99926V3.00301H7.00051Z" fill="#D14343" />
              </svg>
              <div className="error-message-holder">
                <p className="error-message">{error.confirmPassword}</p>
              </div>
            </div>}
          </div>

          {error.authfail && <div className="error-message-box auth-page-error-box">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M7.99989 0.00488281C3.58265 0.00488281 0.00488281 3.58265 0.00488281 7.99989C0.00488281 12.4171 3.58265 15.9949 7.99989 15.9949C12.4171 15.9949 15.9949 12.4171 15.9949 7.99989C15.9949 3.58265 12.4171 0.00488281 7.99989 0.00488281ZM7.00051 12.9968V10.998H8.99926V12.9968H7.00051ZM7.00051 3.00301V9.99864H8.99926V3.00301H7.00051Z" fill="#D14343" />
            </svg>
            <div className="error-message-holder">
              <p className="error-message">Error</p>
              <p className="error-message-text" dangerouslySetInnerHTML={{ __html: error.authfail }} />
            </div>
          </div>}

          {/* Show the loader while signup is in progress */}
          {loadingAuth ? (
            <div className="d-flex justify-content-center mb-3">
              <div className="spinner-border" role="status">
                <span className="sr-only"></span>
              </div>
            </div>
          ) : (
            <button type="submit" className="btn btn-primary w-100 btn-lg mb-3 auth-submit-btn">
              Sign Up
            </button>
          )}
        </form>
      )}

      {/* Link for switching to Login page */}
      <div className="text-start p-3">
        Already have an account?{" "}
        <a onClick={toggleSignup} className="">
          Sign In!
        </a>
      </div>
    </>
  );
};

export default SbSignupComponent;
