import React from "react";
import SB_DASHBOARD from "../../Assets/Images/sb_dashboard.png";

const SbDashboard = () => {

  return (
    <div className="skills-Bridger">
      <img src={SB_DASHBOARD} width={"100%"}></img>
    </div>
  );
};

export default SbDashboard;
