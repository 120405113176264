// // src/api/axiosInstance.js
// import axios from "axios";
// import { useContext } from "react";
// import { API_ENDPOINTS } from "../config/ApiConfig";
// import { AuthContext } from "../State/AuthContext";

// // Create Axios instance
// const axiosInstance = axios.create({
//   timeout: 50000, // Set a timeout (optional)
// });

// // Request interceptor to validate token
// axiosInstance.interceptors.request.use(
//   (config) => {
//     const handleInvalidToken = () => {
//       // Define INSIDE the request interceptor
//       logout(); // Now logout will work correctly
//     };

//     const validateToken = async (token) => {
//       if (token) {
//         try {
//           const validateResponse = await axios.get(API_ENDPOINTS.PROTECTED, {
//             headers: { Authorization: `Bearer ${token}` },
//           });

//           if (validateResponse.status === 200) {
//             config.headers.Authorization = `Bearer ${token}`; // Attach the token
//           } else {
//             throw new Error("Token validation failed"); // Throw error if not 200
//           }
//         } catch (error) {
//           handleInvalidToken(); // Call the logout handler
//           throw error; // Re-throw to stop the request
//         }
//       }

//       return config;
//     };

//     const token = localStorage.getItem("access_token");
//     const { logout } = useContext(AuthContext);

//     return validateToken(token, logout).then((validatedConfig) => {
//       // pass the validated config to axiosInstance
//       return axiosInstance(validatedConfig);
//     });
//   },
//   (error) => Promise.reject(error) // Handle request errors
// );

// // Response interceptor to handle 401 errors
// axiosInstance.interceptors.response.use(
//   (response) => response, // Pass through successful responses
//   (error) => {
//     if (error.response?.status === 401) {
//       console.error("Unauthorized. Logging out...");
//       const { logout } = AuthContext(); // Get the context in a non-component way
//       logout(); // Call logout
//     }
//     return Promise.reject(error);
//   }
// );

// export default axiosInstance;

// axiosInstance.js
import axios from "axios";
import { API_ENDPOINTS } from "../config/ApiConfig";


const axiosInstance = axios.create({
  timeout: 9000000,
});

// Request interceptor to add token and handle 401
axiosInstance.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("access_token");

    if (token) {
      // Only validate if a token exists

      try {
        const validateResponse = await axios.get(API_ENDPOINTS.PROTECTED, {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (validateResponse.status !== 200) {
          // Check for any unsuccessful response (not just 401)

          throw new Error("Token validation failed");
        }

        config.headers.Authorization = `Bearer ${token}`;
      } catch (error) {
        // Call the logout API
        try {
          await axios.delete(API_ENDPOINTS.LOGOUT, {
            headers: { Authorization: `Bearer ${token}` }, // Include token if needed
          });
        } catch (logoutError) {
          console.error("Error calling logout API:", logoutError);
          // Handle/display logout API error if needed.
        } finally {
          window.location.href = "/"; // Redirect AFTER API call attempt
          return Promise.reject(error); // Reject the original promise to stop the request
        }
      }
    }

    return config;
  },

  (error) => Promise.reject(error)
);

export default axiosInstance; // Regular export
