import React, { createContext, useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { logoutUser, getEntitlement, fetchOrgMetaData } from "../Api/apiService";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [entitlements, setEntitlements] = useState([]);
  const [entitlementsData, setEntitlementsData] = useState([]);
  const [email, setEmail] = useState(localStorage.getItem("user_email"));
  const [loadingEntitlements, setLoadingEntitlements] = useState(true); // Loading state
  const [initialLoad, setInitialLoad] = useState(true); // Add initial load state

  const [profileName, setProfileName] = useState("");
  const [profileRole, setProfileRole] = useState("");
  const [orgDomain, setOrgDomain] = useState("");
  const [subscriptionType, setSubscriptionType] = useState("");
  const [orgMetaData, setOrgMetaData] = useState([]);
  const [loadingOrgMetaData, setLoadingOrgMetaData] = useState(true); // Loading state

  // const [accessToken, setAccessToken] = useState(
  //   localStorage.getItem("access_token")
  // );
  // const [refreshToken, setRefreshToken] = useState(
  //   localStorage.getItem("refresh_token")
  // );


  const [accessToken, setAccessToken] = useState();
  const [refreshToken, setRefreshToken] = useState();

  const navigate = useNavigate();

  const logout = useCallback(async () => {
    try {
      if (accessToken) {
        // Check if accessToken exists before calling logoutUser
        await logoutUser(accessToken, refreshToken); // use accessToken and refreshToken
      }
    } catch (error) {
      console.error("Logout error:", error);
      // Optional: Add user notification here (e.g., a toast or alert)
    }

    clearAuthState(); // Call AFTER the API call (if any)
    navigate("/");
  }, [accessToken, refreshToken]); // Add accessToken and refreshToken as dependencies

  // useEffect(() => {
  //   setLogoutDispatcher(() => logout);
  // }, [logout]);

  const fetchAndSetEntitlements = async () => {
    // Fetch and merge
    if (!accessToken) {
      setLoadingEntitlements(false);
      return;
    }

    setLoadingEntitlements(true);

    try {
      const entitlementsData = await getEntitlement(accessToken);

      //HARDCODED for testing, change above var back once finished: entitlementsData
      // const entitlementsData = [
      //   {
      //     resources: [
      //       {
      //         entitlements: ["Jobs"],
      //         name: "Tabs",
      //       },
      //     ],
      //     role: "hr_bp",
      //     role_id: "NQRsBctwQ2FJhK5",
      //   },
      //   {
      //     resources: [
      //       {
      //         entitlements: ["Skills"],
      //         name: "Tabs",
      //       },
      //     ],
      //     role: "hr",
      //     role_id: "NQRsBctwQ2FJhK2",
      //   },
      // ];
      setEntitlementsData(entitlementsData)
      // Merge entitlements (assuming entitlementsData is an array of roles with resources)
      const mergedEntitlements = {};
      entitlementsData.forEach((role) => {
        role.resources.forEach((resource) => {
          mergedEntitlements[resource.name] =
            mergedEntitlements[resource.name] || [];
          mergedEntitlements[resource.name].push(...resource.entitlements);

          // Ensure unique entitlements (optional)
          mergedEntitlements[resource.name] = [
            ...new Set(mergedEntitlements[resource.name]),
          ];
        });
      });

      setEntitlements(mergedEntitlements); // Set merged entitlements to state
    } catch (error) {
      console.error("Error fetching entitlements:", error);
      if (
        error.message.includes("Unauthorized") ||
        (error.response && error.response.status === 401)
      ) {
        logout(); // Call logout if unauthorized
        return; // Important: stop further execution
      }
      setEntitlements({}); // Set to empty object or handle as needed
    } finally {
      setLoadingEntitlements(false);
      // Only navigate on the initial render, when accessToken is set the first time
      if (initialLoad) {
        setInitialLoad(false); // Set initialLoad to false so it doesn't navigate again
      }
    }
  };

  const fetchAndSetOrgMetaData = async () => {

    // Fetch and merge
    if (!accessToken) {
      setLoadingOrgMetaData(false);
      return;
    }
    setLoadingOrgMetaData(true);
    try {
      const orgMetaDataVal = await fetchOrgMetaData(accessToken);
      if (orgMetaDataVal) {
        setOrgMetaData(orgMetaDataVal)
      }

    } catch (error) {
      console.error("Error fetching orgMetaData:", error);
      if (
        error.message.includes("Unauthorized") ||
        (error.response && error.response.status === 401)
      ) {
        logout(); // Call logout if unauthorized
        return; // Important: stop further execution
      }
      setOrgMetaData({}); // Set to empty object or handle as needed
    } finally {
      setLoadingOrgMetaData(false);
      if (initialLoad) {
        setInitialLoad(false); // Set initialLoad to false so it doesn't navigate again
      }
    }
  };

  useEffect(() => {
    fetchAndSetEntitlements();
    fetchAndSetOrgMetaData();
  }, [accessToken, initialLoad]);

  const clearAuthState = () => {
    setEmail(null);
    setAccessToken(null);
    setRefreshToken(null);
    localStorage.removeItem("user_email");
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
  };

  return (
    <AuthContext.Provider
      value={{
        email,
        setEmail,
        accessToken,
        setAccessToken,
        refreshToken,
        setRefreshToken,
        logout,
        entitlements,
        loadingEntitlements,
        entitlementsData,
        profileName,
        setProfileName,
        profileRole,
        setProfileRole,
        orgMetaData,
        loadingOrgMetaData,
        orgDomain,
        setOrgDomain,
        subscriptionType,
        setSubscriptionType
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
