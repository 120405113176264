import React, { useContext } from "react";
import { AuthFunctionsContext } from "../../State/AuthFunctionsContext";

const SbLoginComponent = () => {
  const {
    username,
    setUsername,
    password,
    setPassword,
    error,
    sb_auth_handleLogin,
    sb_auth_handleBlur,
    loadingAuth,
    toggleSignup,
    toggleForgotPassword,
    authStatus,
  } = useContext(AuthFunctionsContext);

  const handleForgotPasswordClick = (e) => {
    e.preventDefault();
    toggleForgotPassword(); // Toggle to forgot password view
  };


  return (
    <>
      {authStatus && (

        <div className="success-message-box mt-3 auth-page-success-box">
          <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M0 8C0 12.42 3.58 16 8 16C12.42 16 16 12.42 16 8C16 3.58 12.42 0 8 0C3.58 0 0 3.58 0 8ZM11.29 5.29C11.47 5.11 11.72 5 12 5C12.55 5 13 5.45 13 6C13 6.28 12.89 6.53 12.71 6.71L7.71 11.71C7.53 11.89 7.28 12 7 12C6.72 12 6.47 11.89 6.29 11.71L3.29 8.71C3.11 8.53 3 8.28 3 8C3 7.45 3.45 7 4 7C4.28 7 4.53 7.11 4.71 7.29L7 9.59L11.29 5.29Z" fill="#52BD94" />
          </svg>
          <div className="success-message-holder">
            <p className="success-message">Success</p>
            <p className="success-message-text">{authStatus}</p>
          </div>

        </div>

      )}

      <form onSubmit={sb_auth_handleLogin}>
        <div className="mb-3">
          <label htmlFor="email" className="form-label w-100 text-start">
            Business Email
          </label>
          <input
            type="email"
            className="form-control"
            id="email"
            value={username}
            onBlur={sb_auth_handleBlur}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
          {/* {error.username && (
            <p className="text-start mt-1 error-message text-danger">{error.username}</p>
          )} */}

          {error.username && <div className="error-message-box auth-page-error-box">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M7.99989 0.00488281C3.58265 0.00488281 0.00488281 3.58265 0.00488281 7.99989C0.00488281 12.4171 3.58265 15.9949 7.99989 15.9949C12.4171 15.9949 15.9949 12.4171 15.9949 7.99989C15.9949 3.58265 12.4171 0.00488281 7.99989 0.00488281ZM7.00051 12.9968V10.998H8.99926V12.9968H7.00051ZM7.00051 3.00301V9.99864H8.99926V3.00301H7.00051Z" fill="#D14343" />
            </svg>
            <div className="error-message-holder">
              <p className="error-message">{error.username}</p>
            </div>
          </div>}

        </div>

        <div className="mb-3">
          <label htmlFor="password" className="form-label w-100 text-start">
            Password
          </label>
          <input
            type="password"
            className="form-control"
            id="password"
            value={password}
            onBlur={sb_auth_handleBlur}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          {/* {error.password && (
            <p className="text-start mt-1 error-message text-danger">{error.password}</p>
          )} */}

          {error.password && <div className="error-message-box auth-page-error-box">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M7.99989 0.00488281C3.58265 0.00488281 0.00488281 3.58265 0.00488281 7.99989C0.00488281 12.4171 3.58265 15.9949 7.99989 15.9949C12.4171 15.9949 15.9949 12.4171 15.9949 7.99989C15.9949 3.58265 12.4171 0.00488281 7.99989 0.00488281ZM7.00051 12.9968V10.998H8.99926V12.9968H7.00051ZM7.00051 3.00301V9.99864H8.99926V3.00301H7.00051Z" fill="#D14343" />
            </svg>
            <div className="error-message-holder">
              <p className="error-message">{error.password}</p>
            </div>
          </div>}


        </div>
        <div className="text-end p-3">
          <a onClick={handleForgotPasswordClick} className="">
            Forgot password?
          </a>
        </div>

        {/* {error.authfail && (
          <p className="text-start mt-1 error-message text-danger">{error.authfail}</p>
        )} */}

        {error.authfail && <div className="error-message-box auth-page-error-box">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M7.99989 0.00488281C3.58265 0.00488281 0.00488281 3.58265 0.00488281 7.99989C0.00488281 12.4171 3.58265 15.9949 7.99989 15.9949C12.4171 15.9949 15.9949 12.4171 15.9949 7.99989C15.9949 3.58265 12.4171 0.00488281 7.99989 0.00488281ZM7.00051 12.9968V10.998H8.99926V12.9968H7.00051ZM7.00051 3.00301V9.99864H8.99926V3.00301H7.00051Z" fill="#D14343" />
          </svg>
          <div className="error-message-holder">
            <p className="error-message">Error</p>
            <p className="error-message-text" dangerouslySetInnerHTML={{ __html: error.authfail }} />
          </div>
        </div>}
        {/* Show the loader while login is in progress */}
        {loadingAuth ? (
          <div className="d-flex justify-content-center mb-3">
            <div className="spinner-border" role="status">
              <span className="sr-only"></span>
            </div>
          </div>
        ) : (
          <button type="submit" className="btn btn-primary w-100 btn-lg mb-3 auth-submit-btn">
            Sign in
          </button>
        )}
      </form>

      <div className="text-start p-3">
        <span>Don't have an account?</span>{" "}
        <a onClick={toggleSignup} className="">
          Sign up!
        </a>
      </div>
    </>
  );
};

export default SbLoginComponent;
