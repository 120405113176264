//component for job details

import React, { useState, useEffect, useContext, useRef } from "react";
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Row, Col, Form } from 'react-bootstrap';
import { getVersions, getOGJobDescription, getSBJobDescription, getOgjdEvalScore, getSbjdEvalScore, getCompareData, getAutomationPotential } from "../../Api/apiService";
import { marked } from 'marked';
import JDDiffChecker from '../../Components/SbJdDiffCheckerSubComponent/JDDiffChecker';
import MarkdownEditor from "react-markdown-editor-lite";
import "react-markdown-editor-lite/lib/index.css";
import "./SbJobDetails.scss"
import { AuthContext } from "../../State/AuthContext";
import Table from 'react-bootstrap/Table';
import * as changeCase from "change-case";
import ReactDiffViewer from 'react-diff-viewer-continued';
import sbLogo from '../../Assets/Images/sb_logo.png'
import { PDFDownloadLink, Document, Page, Text, View, Image, StyleSheet, Font } from '@react-pdf/renderer';
import SbTaskAutomation from '../../Components/SbJobDetailsComponent/SbJobDetailsSubComponents/SbTaskAutomation'
import { JobDataContext } from "../../State/JobsDataContext";
import { useNavigate } from 'react-router-dom';

//Instead of using TypeScript enum, use a simple JavaScript object
const DiffMethod = {
  CHARS: 'diffChars',
  WORDS: 'diffWords',
  WORDS_WITH_SPACE: 'diffWordsWithSpace',
  LINES: 'diffLines',
  TRIMMED_LINES: 'diffTrimmedLines',
  SENTENCES: 'diffSentences',
  CSS: 'diffCss',
};

const SbJobDetails = () => {

  const { selectedJob } = useContext(JobDataContext);
  const navigate = useNavigate(); // Hook for navigation

  const jobData = selectedJob;

  const { email, accessToken, subscriptionType } = useContext(AuthContext);

  const handleOnBackClick = () => {
    // Decide the parameter value (e.g., `true` to toggle drawer, `false` to not toggle)
    //onBack(true);  // or onBack(false);
    navigate(`/jobs-library`);

  };

  const [activeTab, setActiveTab] = useState('jd');
  const [versionData, setVersionData] = useState([]);
  const [ogJd, setOgJd] = useState([]);
  const [sbJd, setSbJd] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isEditable, setIsEditable] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showFullScreen, setshowFullScreen] = useState(false);
  const [showDiff, setShowDiff] = useState(false);
  const [selectedJdVersion, setSelectedJdVersion] = useState(1); // For JD Version dropdown
  const [sbUniqueId, setSbUniqueId] = useState('');
  const [ogJdEval, setOgJdEval] = useState([]);
  const [sbJdEval, setSbJdEval] = useState([]);
  const [updatedJD, setUpdatedJD] = useState(null); // State to hold the updated JD
  const [editableMarkdown, setEditableMarkdown] = useState("");
  const { selectedJdTab, setSelectedJdTab } = useContext(JobDataContext);
  const [meanOgJdEval, setMeanOgJdEval] = useState([]);
  const [meanSbJdEval, setMeanSbJdEval] = useState([]);
  const [comparisonSummary, setComparisonSummary] = useState([]);
  const [showEvalDrawer, setshowEvalDrawer] = useState(false);
  const [blurredJD, setBlurredJD] = useState(false);

  const [skillsData, setSkillsData] = useState([]);
  const [overallPotential, setOverallPotential] = useState(null);
  const [loadingAP, setLoadingAP] = useState(false);

  const handleSelectedJdTab = (e) => {
    setSelectedJdTab(e.target.value);
  };


  const handleEvalDrawerToggle = () => {
    setshowEvalDrawer(prevState => !prevState); // Toggle dropdown visibility
  };

  const handleTabClick = (tabKey) => {
    setActiveTab(tabKey);
    if (tabKey !== "jd") {
      setSelectedJdTab('job-description');
    }
  };

  const sb_jd_fetchVersions = async () => {
    setLoading(true);
    setError(null); // Reset error before each fetch

    try {
      // Send API call
      const fetchVersionsResponse = await getVersions(email, jobData.job_id, accessToken);

      if (fetchVersionsResponse) {
        setVersionData(fetchVersionsResponse);
      } else {
        setError('No versions found or invalid response.');
      }
    } catch (err) {
      console.error('Error fetching roles:', err);
      setError('Failed to fetch versions. Please try again.');
    } finally {
      //setLoading(false);  // Set loading to false once fetch is done
    }
  };

  const sb_jd_fetchSBJobDescription = async (sbUniqueId) => {
    setLoading(true);
    setError(null); // Reset error before each fetch
    // If sbUniqueId is provided, use it. Otherwise, fall back to jobData.job_id
    const idToUse = sbUniqueId || jobData.job_id;
    try {
      // Send API call to fetch the job description
      const fetchSBJobDescriptionResp = await getSBJobDescription(idToUse, email, accessToken);

      if (fetchSBJobDescriptionResp) {

        if (fetchSBJobDescriptionResp["sb_job_description"]) {
          setSbJd(fetchSBJobDescriptionResp["sb_job_description"]);
          setEditableMarkdown(fetchSBJobDescriptionResp["sb_job_description"]);
          await sb_jd_fetchSbJdEvalScore(idToUse);
        }

        if (fetchSBJobDescriptionResp["jd_input_type"] === "upload") {
          await sb_jd_fetchOGJobDescription();
          await sb_jd_comparisonSummary(idToUse);
        }

        if (fetchSBJobDescriptionResp["is_preview"] === true && fetchSBJobDescriptionResp["message"]) {
          setBlurredJD(true)
        }

      } else {
        setError('No JD found or invalid response.');
      }
    } catch (err) {
      console.error('Error fetching JD:', err);
      setError('Failed to fetch JD. Please try again.');
    } finally {
      setLoading(false);  // Set loading to false once fetch is done
    }
  };

  const sb_jd_fetchSbJdEvalScore = async (sbUniqueId) => {
    setLoading(true);
    setError(null); // Reset error before each fetch

    try {
      // If sbUniqueId is provided, use it. Otherwise, fall back to jobData.job_id
      const idToUse = sbUniqueId || jobData.job_id;

      // Send API call to fetch the job description
      const fetchSbJdEvalScoreResp = await getSbjdEvalScore(idToUse, email, accessToken);

      if (fetchSbJdEvalScoreResp) {

        // Extract the original JD evaluation score data
        const sbEvalScoreData = fetchSbJdEvalScoreResp.generated_jd_eval_score;

        // Create an array of entries to be sorted based on the 'order' field
        const sortedSbScores = Object.entries(sbEvalScoreData)
          .sort((a, b) => a[1].order - b[1].order); // Sort by order field (ascending)

        // Create the new object with the correct order and store the score values only
        const reorderedSbEvalScores = {};

        // Iterate through sorted entries and populate the new object with scores
        sortedSbScores.forEach(([key, value]) => {
          reorderedSbEvalScores[key] = value.score; // Store only the score value
        });

        // Convert the object into an array of items that can be mapped
        const meanItemsSb = Object.entries(reorderedSbEvalScores).map(([key, score]) => ({
          name: key,
          value: score,
        }));

        // Store the filtered mean values (if needed, you can set it to a new state here)
        setSbJdEval(fetchSbJdEvalScoreResp.generated_jd_eval_score);
        setMeanSbJdEval(meanItemsSb);

      } else {
        setError('No JD found or invalid response.');
      }
    } catch (err) {
      console.error('Error fetching sb JD eval:', err);
      setError('Failed to fetch JD Evaluation Score. Please try again.');
    } finally {
      //setLoading(false);
    }
  };

  const sb_jd_fetchOGJobDescription = async () => {
    setLoading(true);
    setError(null); // Reset error before each fetch

    try {
      // Send API call
      const fetchOGJobDescriptionResp = await getOGJobDescription(jobData.job_id, email, accessToken);

      if (fetchOGJobDescriptionResp) {

        if (fetchOGJobDescriptionResp["original_job_description"]) {
          setOgJd(fetchOGJobDescriptionResp.original_job_description);
          await sb_jd_fetchOgJdEvalScore();
        }
      } else {
        setError('No JD found or invalid response.');
      }
    } catch (err) {
      console.error('Error fetching JD:', err);
      setError('Failed to fetch JD. Please try again.');
    } finally {
      //setLoading(false);  // Set loading to false once fetch is done
    }
  };

  const sb_jd_fetchOgJdEvalScore = async () => {
    setLoading(true);
    setError(null); // Reset error before each fetch

    try {
      // Send API call
      const fetchOgJdEvalScoreResp = await getOgjdEvalScore(jobData.job_id, email, accessToken);

      if (fetchOgJdEvalScoreResp.original_jd_eval_score) {

        // Extract the original JD evaluation score data
        const evalScoreData = fetchOgJdEvalScoreResp.original_jd_eval_score;

        // Create an array of entries to be sorted based on the 'order' field
        const sortedScores = Object.entries(evalScoreData)
          .sort((a, b) => a[1].order - b[1].order); // Sort by order field (ascending)

        // Now create the new object with the correct order and store the score values only
        const reorderedEvalScores = {};

        // Iterate through sorted entries and populate the new object with scores
        sortedScores.forEach(([key, value]) => {
          reorderedEvalScores[key] = value.score; // Store only the score value
        });

        // Convert the object into an array of items that can be mapped
        const meanItems = Object.entries(reorderedEvalScores).map(([key, score]) => ({
          name: key,
          value: score,
        }));

        // Store the filtered mean values (if needed, you can set it to a new state here)
        setOgJdEval(fetchOgJdEvalScoreResp.original_jd_eval_score);
        setMeanOgJdEval(meanItems);
      } else {
        setError('Failed to fetch JD Eval Score. Please try again');
      }
    } catch (err) {
      console.error('Error fetching JD Eval Score:', err);
      setError('Failed to fetch JD Eval Score. Please try again.');
    } finally {
      //setLoading(false);  // Set loading to false once fetch is done
    }
  };

  const sb_jd_comparisonSummary = async (sbUniqueId) => {
    setLoading(true);
    setError(null);

    try {
      // If sbUniqueId is provided, use it. Otherwise, fall back to jobData.job_id
      const idToUse = sbUniqueId || jobData.job_id;

      // Send API call to fetch the job description
      const comparisonSummaryResp = await getCompareData(idToUse, email, accessToken);

      if (comparisonSummaryResp.compare_data.difference_table.jobDescriptionComparison) {
        setComparisonSummary(comparisonSummaryResp.compare_data.difference_table.jobDescriptionComparison);
      } else {
        setError('No JD found or invalid response.');
      }
    } catch (err) {
      console.error('Error fetching comparison summary:', err);
      setError('Error fetching comparison summary');
    } finally {
      //setLoading(false);  // Set loading to false once fetch is done
    }
  };

  // A reference to store cached data for jobId
  const cachedData = useRef({});

  useEffect(() => {
    const fetchTaskAutomationData = async () => {
      setLoadingAP(true);

      // Check if the data is already cached for the current jobId
      if (cachedData.current[jobData.job_id]) {
        // Use the cached data
        const cachedResponse = cachedData.current[jobData.job_id];
        setSkillsData(cachedResponse.skillsData);
        setOverallPotential(cachedResponse.overallPotential);
        setLoadingAP(false);
        return;
      }

      try {
        const response = await getAutomationPotential(jobData.job_id, email);
        if (response?.automation_potential) {
          const { automation_potential } = response.automation_potential;
          const overall = parseInt(response.automation_potential.overall_role_automation_potential);

          // Save response to cache
          cachedData.current[jobData.job_id] = {
            skillsData: automation_potential,
            overallPotential: overall,
          };

          // Update state with the new data
          setSkillsData(automation_potential);
          setOverallPotential(overall);
        }
      } catch (error) {
        console.error("Error fetching automation potential data:", error);
      } finally {
        setLoadingAP(false); // Set loading to false once fetch is done
      }
    };

    fetchTaskAutomationData();
  }, [jobData.job_id]);

  // Effect Hook to fetch job description when sbUniqueId or jobData.job_id changes
  useEffect(() => {
    // Initially, sbUniqueId is empty, so fallback to jobData.job_id
    sb_jd_fetchSBJobDescription('');
    // sb_jd_fetchSbJdEvalScore('');
    // sb_jd_comparisonSummary('')
  }, [jobData.job_id]); // Only re-run if job_id changes

  // Triggering fetch when sbUniqueId changes (or on load)
  const handleSbUniqueIdChange = (newSbUniqueId) => {
    sb_jd_fetchSBJobDescription(newSbUniqueId);
    // sb_jd_fetchSbJdEvalScore(newSbUniqueId)
    // sb_jd_comparisonSummary(newSbUniqueId)
  };

  // API call handler for version change
  const handleVersionChange = (e) => {
    const selectedVersion = e.target.value;
    setSelectedJdVersion(selectedVersion);
    // Find the selected version data from the versionData array
    const selectedVersionData = versionData.find((version) => version.version == selectedVersion);
    if (selectedVersionData) {
      setSbUniqueId(selectedVersionData.sb_unique_id);
      handleSbUniqueIdChange(selectedVersionData.sb_unique_id); // Fetch job description based on sb_unique_id
    }

  };

  // Use useEffect to run the function when the component loads
  useEffect(() => {
    sb_jd_fetchVersions();
    // sb_jd_fetchOGJobDescription();
    // sb_jd_fetchOgJdEvalScore();
  }, []);  // Empty dependency array makes it run only once on page load


  // Convert markdown to HTML
  const renderMarkdownToHtml = (markdown) => {
    return marked(markdown);
  };

  // Render markdown to HTML for the editor
  const renderHTML = (text) => {
    return renderMarkdownToHtml(text); // Convert markdown to HTML
  };

  // Function to convert HTML to plain text
  const convertHtmlToPlainText = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.innerText; // Extracts plain text from HTML
  };

  const handleEditClick = () => {
    setIsEditable(true);
    setShowDiff(false);
  };

  const handleCancelClick = () => {
    setIsEditable(false); // Disable edit mode
  };

  const handleSaveClick = () => {
    setUpdatedJD(editableMarkdown);
    setIsEditable(false);
  };

  const handleDiffClick = () => {
    setIsEditable(false);
    setShowDiff(!showDiff);
  };

  function convertDateFormat(inputDateString) {
    // Parse the input date string to a Date object
    const date = new Date(inputDateString);

    // Format the date to "Feb 13 2025"
    const formattedDate = date.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });

    return formattedDate;
  }

  const styles = StyleSheet.create({

    page: {
      flexDirection: 'column',
      padding: 30,
      fontFamily: 'Helvetica', // Use Inter font
      fontSize: 12, // Adjust the font size
      lineHeight: 1.5, // Adjust the line height
      position: 'relative', // To allow absolute positioning inside the page
    },
    logoContainer: {
      position: 'absolute',
      top: 15,  // Adjust this to position the logo at the top
      left: 15, // Adjust this to position the logo at the left
      width: 200, // Adjust logo size
      height: 50, // Adjust logo size
      zIndex: 1, // Ensure logo stays on top of other content
    },
    content: {
      marginTop: 70, // Adjust this to ensure content doesn't overlap with the logo
    },
    disclaimer: {
      marginTop: 20,
    },
    section: {
      marginBottom: 20,
    },
    text: {
      fontFamily: 'Helvetica', // Apply the Inter font
      fontSize: 12, // Adjust the font size
      lineHeight: 1.5, // Adjust the line height
    },
  });

  // A function to recursively parse the DOM and render React PDF components
  const renderHtmlToReactPdf = (node) => {
    if (node.nodeType === Node.TEXT_NODE) {
      return <Text>{node.textContent}</Text>;
    }

    if (node.nodeName === 'P') {
      return <Text>{node.textContent}</Text>;
    }

    if (node.nodeName === 'H1' || node.nodeName === 'H2' || node.nodeName === 'H3') {
      return <Text style={{ fontWeight: 'bold', fontSize: 18 }}>{node.textContent}</Text>;
    }

    if (node.nodeName === 'UL') {
      return (
        <View>
          {Array.from(node.children).map((li, index) => (
            <Text key={index} style={{ marginLeft: 20 }}>
              {'• ' + li.textContent}
            </Text>
          ))}
        </View>
      );
    }

    if (node.nodeName === 'OL') {
      return (
        <View>
          {Array.from(node.children).map((li, index) => (
            <Text key={index} style={{ marginLeft: 20 }}>
              {index + 1}. {li.textContent}
            </Text>
          ))}
        </View>
      );
    }

    return null; // For other unsupported tags
  };

  const htmlContent = renderMarkdownToHtml(editableMarkdown);
  const parsedContent = new DOMParser().parseFromString(htmlContent, 'text/html');

  // Extract all child nodes from the parsed HTML and map them to React components
  const parsedReactComponents = Array.from(parsedContent.body.childNodes).map((node, index) => (
    <View key={index}>{renderHtmlToReactPdf(node)}</View>
  ));

  const renderDisclaimer = () => (
    <View style={styles.section}>
      <Text style={{ fontWeight: 'bold' }}>Disclaimer:</Text>
      <Text>
        This job description is provided for illustrative and demonstration purposes only. It was generated using artificial intelligence technology. As an AI-generated document, it may not fully reflect current industry standards, specific organizational needs, or human expertise.
      </Text>
      <Text style={styles.disclaimer}>
        Users are encouraged to independently verify the accuracy and applicability of this job description to their specific situation before relying on it for any decisions.
      </Text>
      <Text>
        SkillsBridge.AI disclaims all liability for any errors, omissions, inaccuracies, or potential biases in this document. Any reliance you place on such information is strictly at your own risk. Unauthorized use, disclosure, or distribution is strictly prohibited.
      </Text>
    </View>
  );

  // Create PDF Document
  const SbJdDocument = () => (
    <Document>
      {/* Add the logo with absolute positioning */}
      <Page style={styles.page}>
        <View style={styles.logoContainer}>
          <Image style={styles.logo} src={sbLogo} />
        </View>

        {/* Render the actual content across multiple pages */}
        <View style={styles.content}>
          {parsedReactComponents}
          {renderDisclaimer()}
        </View>
      </Page>
    </Document>
  );


  return (
    <Card className='border-0 rounded-0 shadow-sm'>
      <Card.Body>
        <div className="d-flex align-items-center">
          <Button variant="link" onClick={handleOnBackClick} className="me-3">
            {/* Back icon */}
            <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_1478_14276)">
                <path d="M29.334 11L4.88954 11" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M11 4.58203L4.58333 10.9987L11 17.4154" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
              </g>
              <defs>
                <clipPath id="clip0_1478_14276">
                  <rect width="24" height="22" fill="white" transform="matrix(-1 0 0 1 24 0)" />
                </clipPath>
              </defs>
            </svg>
          </Button>
          <Card.Title className="text-start jobs-heading">
            Job Details: {jobData.job_title}
            <small className="text-body-tertiary jobs-description ms-3">
              Business: {jobData.business_unit || 'N/A'}, Location: {jobData.location || 'N/A'},
              Function: {jobData.function_level || 'N/A'}, Level: {jobData.level || 'N/A'}
            </small>
          </Card.Title>
        </div>

        {/* Custom Tabs with divs */}
        <div className="custom-tabs mb-3">
          <div className="tab-titles">
            <div
              className={`tab-title ${activeTab === 'jd' ? 'active' : ''}`}
              onClick={() => handleTabClick('jd')}
            >
              Jobs Library
            </div>
            <div
              className={`tab-title ${activeTab === 'skills-bridger' ? 'active' : ''} ${subscriptionType === "free" ? 'disabled' : ''}`}
              onClick={subscriptionType === "free" ? null : () => handleTabClick('skills-bridger')}
              style={{ cursor: subscriptionType === "free" ? 'not-allowed' : 'pointer' }}
            >
              Skills Bridger
            </div>

            <div
              className={`tab-title ${activeTab === 'learning-catalyst' ? 'active' : ''} ${subscriptionType === "free" ? 'disabled' : ''}`}
              onClick={subscriptionType === "free" ? null : () => handleTabClick('learning-catalyst')}
              style={{ cursor: subscriptionType === "free" ? 'not-allowed' : 'pointer' }}
            >
              Learning Catalyst
            </div>

            <div
              className={`tab-title ${activeTab === 'talent-mobility' ? 'active' : ''} ${subscriptionType === "free" ? 'disabled' : ''}`}
              onClick={subscriptionType === "free" ? null : () => handleTabClick('talent-mobility')}
              style={{ cursor: subscriptionType === "free" ? 'not-allowed' : 'pointer' }}
            >
              Talent Mobility
            </div>

          </div>

          {activeTab === 'jd' && subscriptionType === "paid" && (
            <Form.Select
              aria-label="Job Description"
              className="me-2 custom-select"
              style={{ width: '300px !important' }}
              value={selectedJdTab}
              onChange={handleSelectedJdTab}
            >

              <option value="job-description">Job Description</option>
              <option value="task-Automation">Task Automation</option>
            </Form.Select>
          )}

          {/* Tab Content */}
          <div className="tab-content">
            {activeTab === 'jd' && selectedJdTab === "job-description" && (
              <div className="tab-pane">
                {!showDiff && !loading &&
                  <div className="grey-bar d-flex justify-content-between align-items-center">
                    {loading ? (
                      <div className="d-flex justify-content-center mb-3">
                        <div className="spinner-border" role="status">
                          <span className="sr-only"></span>
                        </div>
                      </div>) : (
                      <>
                        < div className="jd-version-dropdown d-flex align-items-center">
                          <div className="me-3">
                            <select
                              value={selectedJdVersion}
                              onChange={handleVersionChange}
                              className="dropdown"
                            >
                              {versionData.map((version, index) => (
                                <option key={index} value={version.version}>
                                  {`JD Version - ${version.version}`}
                                </option>

                              ))}
                            </select>
                            {/* Display the modified date of the selected version */}
                            {selectedJdVersion && versionData.length > 0 && (
                              <div>
                                <small className="text-body-tertiary jobs-description">
                                  Modified on {convertDateFormat(versionData.find((version) => version.version == selectedJdVersion)?.data_updated_timestamp)}
                                </small>
                              </div>
                            )}
                          </div>


                          {sbJdEval["Evaluation_Score"] &&
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="d-flex align-items-center flex-column">
                                <button className="dropdown-btn" onClick={handleEvalDrawerToggle}>
                                  <div
                                    className="progress-circle"
                                  >{sbJdEval["Evaluation_Score"]['score']}%
                                  </div>
                                </button>
                                <p className="eval-score-label">Evaluation Score</p>
                              </div>

                            </div>
                          }
                        </div>

                      </>
                    )
                    }

                    {/* Icons on the right */}
                    {!isEditable ? (
                      <div className="icon-group">

                        <div className="icon-wrapper" title="Edit" onClick={blurredJD == true ? undefined : handleEditClick}
                          style={{ cursor: blurredJD == true ? 'not-allowed' : 'pointer' }}
                        >
                          <svg className="" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="30" height="30" rx="3" fill="white" />
                            <path fillRule="evenodd" clipRule="evenodd" d="M19.3536 7.64645C19.1583 7.45118 18.8417 7.45118 18.6465 7.64645L16.6484 9.64452C16.6477 9.64516 16.6471 9.6458 16.6464 9.64645C16.6458 9.64709 16.6452 9.64774 16.6445 9.64839L8.64646 17.6464C8.58238 17.7105 8.53692 17.7908 8.51494 17.8787L7.51494 21.8787C7.47234 22.0491 7.52227 22.2294 7.64646 22.3536C7.77065 22.4777 7.95089 22.5277 8.12128 22.4851L12.1213 21.4851C12.2092 21.4631 12.2895 21.4176 12.3536 21.3536L20.3536 13.3536L22.3536 11.3536C22.5488 11.1583 22.5488 10.8417 22.3536 10.6464L19.3536 7.64645ZM20 12.2929L21.2929 11L19 8.70711L17.7071 10L20 12.2929ZM17 10.7071L19.2929 13L11.7444 20.5485L8.68719 21.3128L9.45149 18.2556L17 10.7071Z" fill="#8790A4" />
                          </svg>
                        </div>

                        <div className="icon-wrapper" title="Regenerate" style={{ cursor: 'not-allowed' }}
                        >
                          <svg style={{ cursor: 'not-allowed' }} className="" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="30" height="30" rx="3" fill="white" />
                            <g clipPath="url(#clip0_1332_10976)">
                              <path d="M15.5713 7.57031H9.857C9.5539 7.57031 9.26321 7.69072 9.04888 7.90505C8.83455 8.11937 8.71415 8.41007 8.71415 8.71317V18.9989L7.57129 22.4275L12.1427 21.2846H21.2856C21.5887 21.2846 21.8794 21.1642 22.0937 20.9499C22.308 20.7355 22.4284 20.4448 22.4284 20.1417V14.4275" stroke="#8790A4" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M16.5575 16.2968L13.1289 16.9139L13.7003 13.4397L19.106 8.05681C19.2123 7.94969 19.3387 7.86466 19.478 7.80664C19.6172 7.74862 19.7666 7.71875 19.9175 7.71875C20.0683 7.71875 20.2177 7.74862 20.357 7.80664C20.4963 7.86466 20.6227 7.94969 20.7289 8.05681L21.9403 9.26823C22.0475 9.37448 22.1325 9.50088 22.1905 9.64015C22.2485 9.77941 22.2784 9.92879 22.2784 10.0797C22.2784 10.2305 22.2485 10.3799 22.1905 10.5192C22.1325 10.6584 22.0475 10.7848 21.9403 10.8911L16.5575 16.2968Z" stroke="#8790A4" strokeLinecap="round" strokeLinejoin="round" />
                            </g>
                            <defs>
                              <clipPath id="clip0_1332_10976">
                                <rect width="16" height="16" fill="white" transform="translate(7 7)" />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                        <div
                          className={`icon-wrapper ${ogJd.length === 0 ? 'disabled' : ''}`}
                          title="Compare"
                          onClick={ogJd.length === 0 ? null : handleDiffClick}
                          style={{ cursor: ogJd.length === 0 ? 'not-allowed' : 'pointer' }}
                        >                          <svg className="" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="30" height="30" rx="3" fill="white" />
                            <path d="M14.0832 24.3761V22.3319H9.14784C8.72556 22.3319 8.37325 22.1908 8.09092 21.9084C7.80859 21.6261 7.66712 21.2735 7.6665 20.8506V9.14659C7.6665 8.72432 7.80798 8.37201 8.09092 8.08968C8.37386 7.80734 8.72617 7.66587 9.14784 7.66526H14.0832V5.62109H14.9998V24.3761H14.0832ZM17.0092 8.58193V7.66526H21.0288C21.4504 7.66526 21.8027 7.80673 22.0857 8.08968C22.3686 8.37262 22.5098 8.72493 22.5092 9.14659V20.8515C22.5092 21.2732 22.368 21.6255 22.0857 21.9084C21.8033 22.1914 21.451 22.3325 21.0288 22.3319H17.0092V21.4153H21.0288C21.1693 21.4153 21.2986 21.3566 21.4165 21.2393C21.5344 21.1219 21.5931 20.9924 21.5925 20.8506V9.14659C21.5925 9.00543 21.5338 8.87587 21.4165 8.75793C21.2992 8.63998 21.1699 8.58132 21.0288 8.58193H17.0092ZM17.0092 15.4569V14.5403H20.2175V15.4569H17.0092ZM17.0092 12.2486V11.3319H20.2175V12.2486H17.0092ZM10.8748 18.6653H14.0832V17.7486H10.8748V18.6653ZM10.8748 15.4569H14.0832V14.5403H10.8748V15.4569ZM10.8748 12.2486H14.0832V11.3319H10.8748V12.2486Z" fill="#8790A4" />
                          </svg>
                        </div>

                        {
                          blurredJD ? (<div className="icon-wrapper" title="Download"
                            style={{ cursor: blurredJD == true ? 'not-allowed' : 'pointer' }}
                          >
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <rect width="30" height="30" rx="3" fill="white" />
                              <path d="M6.5 15.8889V21.2222C6.5 21.6937 6.72388 22.1459 7.1224 22.4793C7.52091 22.8127 8.06141 23 8.625 23H21.375C21.9386 23 22.4791 22.8127 22.8776 22.4793C23.2761 22.1459 23.5 21.6937 23.5 21.2222V15.8889M15 7V17.6667M15 17.6667L11.2812 14.5556M15 17.6667L18.7188 14.5556" stroke="#8790A4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                          </div>) : (

                            <PDFDownloadLink document={<SbJdDocument />} fileName="job-description.pdf">
                              {({ loading }) => (loading ? 'Loading document...' : <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="30" height="30" rx="3" fill="white" />
                                <path d="M6.5 15.8889V21.2222C6.5 21.6937 6.72388 22.1459 7.1224 22.4793C7.52091 22.8127 8.06141 23 8.625 23H21.375C21.9386 23 22.4791 22.8127 22.8776 22.4793C23.2761 22.1459 23.5 21.6937 23.5 21.2222V15.8889M15 7V17.6667M15 17.6667L11.2812 14.5556M15 17.6667L18.7188 14.5556" stroke="#8790A4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                              </svg>)}
                            </PDFDownloadLink>
                          )
                        }


                        {/* 
                        <div className="icon-wrapper" title="Expand">
                          <svg className="icon" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="30" height="30" rx="3" transform="matrix(-1 0 0 1 30 0)" fill="white" />
                            <path d="M18 12L23 7M23 7V11M23 7H19M12 12L7 7M7 7V11M7 7H11M18 18L23 23M23 23V19M23 23H19M12 18L7 23M7 23V19M7 23H11" stroke="#8790A4" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                        </div> */}
                      </div>
                    ) : (
                      <div className="icon-group">
                        {/* <div className="icon-wrapper" title="Expand">
                          <svg className="icon" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="30" height="30" rx="3" transform="matrix(-1 0 0 1 30 0)" fill="white" />
                            <path d="M18 12L23 7M23 7V11M23 7H19M12 12L7 7M7 7V11M7 7H11M18 18L23 23M23 23V19M23 23H19M12 18L7 23M7 23V19M7 23H11" stroke="#8790A4" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                        </div> */}

                        <div className="icon-wrapper" title="Close">
                          <svg onClick={handleCancelClick} className="icon" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="30" height="30" rx="3" fill="#008080" />
                            <path d="M10.3333 20.8332L9.16667 19.6665L13.8333 14.9998L9.16667 10.3332L10.3333 9.1665L15 13.8332L19.6667 9.1665L20.8333 10.3332L16.1667 14.9998L20.8333 19.6665L19.6667 20.8332L15 16.1665L10.3333 20.8332Z" fill="white" />
                          </svg>
                        </div>
                      </div>
                    )}
                  </div>
                }


                {loading ? (
                  <div className="d-flex justify-content-center mb-3">
                    <div className="spinner-border" role="status">
                      <span className="sr-only"></span>
                    </div>
                  </div>) : (
                  <>
                    {!isEditable && !showDiff && sbJd ? (

                      <div>
                        {/* Apply blur effect to the remaining content  add "blurred-content" class name*/}
                        <div id="content-to-download" className={`${blurredJD ? 'blurred-content' : ''}`}>
                          <div
                            className="job-description-html"
                            dangerouslySetInnerHTML={{ __html: renderMarkdownToHtml(editableMarkdown) }}
                          />
                          <div className="job-description-html disclaimer-italics">
                            <h3>Disclaimer:</h3>
                            <p>
                              This job description is provided for illustrative and demonstration purposes only. It was generated using artificial intelligence technology. As an AI-generated document, it may not fully reflect current industry standards, specific organizational needs, or human expertise.
                            </p>
                            <br />
                            <p>
                              Users are encouraged to independently verify the accuracy and applicability of this job description to their specific situation before relying on it for any decisions. The information contained herein is not intended to be, and should not be construed as legal, financial, or professional advice. This document does not create any binding agreement or obligation between parties.
                            </p>
                            <br />
                            <p>
                              SkillsBridge.AI disclaims all liability for any errors, omissions, inaccuracies, or potential biases in this document. Any reliance you place on such information is strictly at your own risk. Unauthorized use, disclosure, or distribution is strictly prohibited.
                            </p>
                          </div>
                        </div>

                        {
                          blurredJD &&
                          <div className="free-trial-exhausted-popup">

                            <h5>Subscribe for access to more JDs!
                            </h5>
                            <p>Thanks for exploring SkillsBridge.AI! Your organization has reached the limit for creating free job descriptions. To unlock the full potential of our platform and view more JDs, a subscription is needed.
                            </p>
                            <p>
                              Don't worry, we're here to help! Our team will be in touch soon to guide you through the process. Please keep an eye on your email for our message.
                            </p>
                          </div>
                        }

                      </div>
                    ) : isEditable ? (
                      <>
                        <MarkdownEditor
                          value={editableMarkdown}
                          onChange={({ text }) => setEditableMarkdown(text)}
                          renderHTML={renderHTML}
                          className="markdown-editor"
                        />

                        <div className="buttom-button-bar d-flex justify-content-end align-items-center">
                          <button className="btn btn-primary btn-md me-2" onClick={handleSaveClick} disabled={!isEditable}>
                            Save
                          </button>
                          <button className="btn btn-primary btn-md" onClick={handleCancelClick} disabled={!isEditable}>
                            Cancel
                          </button>
                        </div>
                      </>
                    ) : showDiff ? (

                      <div className="container">
                        {/* Version Selection Row */}
                        <Row className="">
                          <Col className="bg-tertiary">
                            <div className="d-flex align-items-center">
                              <Form.Group className="custom-form-group me-5">
                                {/* <Form.Label>Original version to compare</Form.Label> */}
                                <Form.Select
                                // value="Original Version"
                                >
                                  <option value="Original Version">
                                    Original Version
                                  </option>
                                </Form.Select>
                                <div style={{ "visibility": "hidden" }}>
                                  <small className="text-body-tertiary jobs-description">
                                    Modified on
                                  </small>
                                </div>
                              </Form.Group>

                              {
                                ogJdEval["Evaluation_Score"] &&
                                <div className="d-flex align-items-center justify-content-between">

                                  <div className="d-flex align-items-center flex-column">
                                    <button className="dropdown-btn" onClick={handleEvalDrawerToggle}>
                                      <div
                                        className="progress-circle"
                                      >{ogJdEval["Evaluation_Score"]['score']}%
                                      </div>
                                    </button>
                                    <p className="eval-score-label">Evaluation Score</p>
                                  </div>
                                </div>
                              }
                            </div>

                          </Col>

                          <Col className="bg-tertiary">
                            <div className="d-flex align-items-center">
                              <Form.Group className="custom-form-group me-5">
                                <Form.Select
                                  value={selectedJdVersion}
                                  onChange={handleVersionChange}
                                >
                                  {versionData.map((version, index) => (
                                    <option key={index} value={version.version}>
                                      {`JD Version - ${version.version}`}
                                    </option>

                                  ))}
                                </Form.Select>
                                {selectedJdVersion && versionData.length > 0 && (
                                  <div>
                                    <small className="text-body-tertiary jobs-description">
                                      Modified on {convertDateFormat(versionData.find((version) => version.version == selectedJdVersion)?.data_updated_timestamp)}
                                    </small>
                                  </div>
                                )}
                              </Form.Group>

                              {sbJdEval["Evaluation_Score"] &&
                                <div className="d-flex align-items-center justify-content-between">
                                  <div className="d-flex align-items-center flex-column">
                                    <button className="dropdown-btn" onClick={handleEvalDrawerToggle}>
                                      <div
                                        className="progress-circle"
                                      >{sbJdEval["Evaluation_Score"]['score']}%
                                      </div>
                                    </button>
                                    <p className="eval-score-label">Evaluation Score</p>
                                  </div>

                                </div>
                              }
                              {/* Buttons */}
                              <div className="d-flex ms-auto">


                                <div className="icon-group">
                                  <div className="icon-wrapper" title="Comparison Summary">

                                    <svg className="icon" onClick={() => setShowModal(true)} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <rect width="30" height="30" rx="3" fill="white" />
                                      <path fillRule="evenodd" clipRule="evenodd" d="M13 8C13.55 8 14 8.45 14 9C14 9.55 13.55 10 13 10H8C7.45 10 7 9.55 7 9C7 8.45 7.45 8 8 8H13ZM13 12C13.55 12 14 12.45 14 13C14 13.55 13.55 14 13 14H8C7.45 14 7 13.55 7 13C7 12.45 7.45 12 8 12H13ZM13 16C13.55 16 14 16.45 14 17C14 17.55 13.55 18 13 18H8C7.45 18 7 17.55 7 17C7 16.45 7.45 16 8 16H13ZM13 20C13.55 20 14 20.45 14 21C14 21.55 13.55 22 13 22H8C7.45 22 7 21.55 7 21C7 20.45 7.45 20 8 20H13ZM22 8C22.55 8 23 8.45 23 9C23 9.55 22.55 10 22 10H17C16.45 10 16 9.55 16 9C16 8.45 16.45 8 17 8H22ZM22 12C22.55 12 23 12.45 23 13C23 13.55 22.55 14 22 14H17C16.45 14 16 13.55 16 13C16 12.45 16.45 12 17 12H22ZM22 16C22.55 16 23 16.45 23 17C23 17.55 22.55 18 22 18H17C16.45 18 16 17.55 16 17C16 16.45 16.45 16 17 16H22ZM22 20C22.55 20 23 20.45 23 21C23 21.55 22.55 22 22 22H17C16.45 22 16 21.55 16 21C16 20.45 16.45 20 17 20H22Z" fill="#8F95B2" />
                                    </svg>

                                  </div>

                                  <div className="icon-wrapper" title="Close">
                                    <svg onClick={handleDiffClick} className="icon" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <rect width="30" height="30" rx="3" fill="#008080" />
                                      <path d="M10.3333 20.8332L9.16667 19.6665L13.8333 14.9998L9.16667 10.3332L10.3333 9.1665L15 13.8332L19.6667 9.1665L20.8333 10.3332L16.1667 14.9998L20.8333 19.6665L19.6667 20.8332L15 16.1665L10.3333 20.8332Z" fill="white" />
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </Col>
                        </Row>

                        {/* VS Separator */}
                        <Row className="vs-row">
                          <Col className="vs-line"></Col>
                          <Col xs="auto" className="p-0">
                            <div className="vs-box">VS</div>
                          </Col>
                          <Col className="vs-line"></Col>
                        </Row>

                        <Row className="">
                          {
                            ogJd && sbJd && (

                              <>
                                {/* <JDDiffChecker
                                  originalJD={ogJd}
                                  revisedJD={sbJd}
                                  width="100%"
                                  height="500px"
                                //onSaveChanges={handleSaveChanges}
                                /> */}

                                <ReactDiffViewer
                                  oldValue={convertHtmlToPlainText(renderMarkdownToHtml(ogJd))}
                                  newValue={convertHtmlToPlainText(renderMarkdownToHtml(sbJd))}
                                  splitView={true}
                                  disableWordDiff={false}
                                  compareMethod={DiffMethod.LINES}
                                />
                              </>

                            )
                          }
                          <Col>
                            {
                              ogJd && !sbJd ? (
                                <div
                                  className="job-description-html"
                                  dangerouslySetInnerHTML={{
                                    __html: renderMarkdownToHtml(ogJd) // Show original JD without any highlighting if sbJd is not available
                                  }}
                                />
                              ) : !ogJd && !sbJd ? (
                                <p>No original JD to compare</p>
                              ) : (
                                <></>)
                            }

                          </Col>

                          <Col>
                            {
                              sbJd && !ogJd ? (
                                <div
                                  className="job-description-html"
                                  dangerouslySetInnerHTML={{
                                    __html: renderMarkdownToHtml(sbJd)
                                  }}
                                />
                              ) : !ogJd && !sbJd ? (
                                <p>No Generated JD to compare</p>
                              ) : (
                                <></>)
                            }
                          </Col>

                        </Row>

                        {/* Modal for Compare Summary */}

                        {showModal && <div className="overlay" onClick={() => setShowModal(false)}></div>}

                        <div className={`summary-pop-up ${showModal ? 'open' : ''} ${showFullScreen ? 'full-modal' : ''}`}>
                          <div className="summary-header">

                            {/* 
                            <div className="summary-icons-middle d-flex align-items-center justify-content-center">

                              <svg onClick={decreaseZoom} width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20.5 12H4.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                              </svg>

                              <div className="summary-zoom-percent">
                                {(zoomLevel * 100).toFixed(0)}% 
                              </div>

                              <svg onClick={increaseZoom} width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.5 4V20M20.5 12L4.5 12" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                              </svg>

                            </div> */}

                            <div className="summary-icons-end d-flex align-items-center justify-content-end">

                              {showFullScreen ? (
                                <div title="Collapse" >
                                  {/* <svg width="24" height="24" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="30" height="30" rx="3" transform="matrix(-1 0 0 1 30 0)" fill="white" />
                                    <path d="M18 12L23 7M23 7V11M23 7H19M12 12L7 7M7 7V11M7 7H11M18 18L23 23M23 23V19M23 23H19M12 18L7 23M7 23V19M7 23H11" stroke="#8790A4" strokeLinecap="round" strokeLinejoin="round" />
                                  </svg> */}
                                  <svg onClick={() => setshowFullScreen(false)} className="me-3" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="30" height="30" rx="3" transform="matrix(-1 0 0 1 30 0)" fill="white" />
                                    <g clip-path="url(#clip0_4498_36841)">
                                      <path d="M22 7.5L17 12.5M17 12.5L17 8.5M17 12.5L21 12.5M28 7.5L33 12.5M33 12.5L33 8.5M33 12.5L29 12.5M22 1.5L17 -3.5M17 -3.5L17 0.5M17 -3.5L21 -3.5M28 1.5L33 -3.5M33 -3.5L33 0.500003M33 -3.5L29 -3.5" stroke="#8790A4" stroke-linecap="round" stroke-linejoin="round" />
                                    </g>
                                    <g clip-path="url(#clip1_4498_36841)">
                                      <path d="M22 22.5L17 17.5M17 17.5L17 21.5M17 17.5L21 17.5M28 22.5L33 17.5M33 17.5L33 21.5M33 17.5L29 17.5M22 28.5L17 33.5M17 33.5L17 29.5M17 33.5L21 33.5M28 28.5L33 33.5M33 33.5L33 29.5M33 33.5L29 33.5" stroke="#8790A4" stroke-linecap="round" stroke-linejoin="round" />
                                    </g>
                                    <g clip-path="url(#clip2_4498_36841)">
                                      <path d="M7 7.5L12 12.5M12 12.5L12 8.5M12 12.5L8 12.5M0.999999 7.5L-4 12.5M-4 12.5L-4 8.5M-4 12.5L0 12.5M7 1.5L12 -3.5M12 -3.5L12 0.5M12 -3.5L8 -3.5M0.999998 1.5L-4 -3.5M-4 -3.5L-4 0.500003M-4 -3.5L-2.79753e-06 -3.5" stroke="#8790A4" stroke-linecap="round" stroke-linejoin="round" />
                                    </g>
                                    <g clip-path="url(#clip3_4498_36841)">
                                      <path d="M7 22.5L12 17.5M12 17.5L12 21.5M12 17.5L8 17.5M0.999999 22.5L-4 17.5M-4 17.5L-4 21.5M-4 17.5L0 17.5M7 28.5L12 33.5M12 33.5L12 29.5M12 33.5L8 33.5M0.999998 28.5L-4 33.5M-4 33.5L-4 29.5M-4 33.5L-2.79753e-06 33.5" stroke="#8790A4" stroke-linecap="round" stroke-linejoin="round" />
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_4498_36841">
                                        <rect width="9" height="8" fill="white" transform="matrix(1 1.74846e-07 1.74846e-07 -1 16 13.5)" />
                                      </clipPath>
                                      <clipPath id="clip1_4498_36841">
                                        <rect width="9" height="8" fill="white" transform="translate(16 16.5)" />
                                      </clipPath>
                                      <clipPath id="clip2_4498_36841">
                                        <rect width="9" height="8" fill="white" transform="translate(13 13.5) rotate(180)" />
                                      </clipPath>
                                      <clipPath id="clip3_4498_36841">
                                        <rect width="9" height="8" fill="white" transform="matrix(-1 -1.74846e-07 -1.74846e-07 1 13 16.5)" />
                                      </clipPath>
                                    </defs>
                                  </svg>

                                </div>) : (
                                <div title="Full Screen">
                                  <svg onClick={() => setshowFullScreen(true)} className="me-3 " width="24" height="24" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="30" height="30" rx="3" transform="matrix(-1 0 0 1 30 0)" fill="white" />
                                    <path d="M18 12L23 7M23 7V11M23 7H19M12 12L7 7M7 7V11M7 7H11M18 18L23 23M23 23V19M23 23H19M12 18L7 23M7 23V19M7 23H11" stroke="#8790A4" strokeLinecap="round" strokeLinejoin="round" />
                                  </svg>
                                </div>
                              )}

                              <div title="Close">
                                <svg onClick={() => setShowModal(false)} className="me-3" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z" fill="white" />
                                </svg>
                              </div>
                            </div>
                          </div>
                          <div className="summary-body">
                            <Row className="mb-3 summary-table-holder">
                              <Col>
                                <div>
                                  {comparisonSummary &&
                                    <table border="1" cellPadding="10" cellSpacing="0">
                                      <thead className="bg-tertiary text-uppercase text-start">
                                        <tr>
                                          <th>Feature</th>
                                          {Object.keys(comparisonSummary).map((key, index) => (
                                            <th key={index}>{key}</th>
                                          ))}
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {Object.keys(comparisonSummary["Original JD"]).map((field, index) => (
                                          <tr key={index}>
                                            <td>{changeCase.sentenceCase(field)}</td>
                                            {Object.keys(comparisonSummary).map((key, index) => {
                                              const value = comparisonSummary[key][field];
                                              return (
                                                <td key={index}>
                                                  {Array.isArray(value) ? (
                                                    <ul>
                                                      {value.map((item, i) => (
                                                        <li key={i}>{changeCase.sentenceCase(item)}</li>
                                                      ))}
                                                    </ul>
                                                  ) : (
                                                    changeCase.sentenceCase(value)
                                                  )}
                                                </td>
                                              );
                                            })}
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                  }
                                </div>
                              </Col>

                            </Row>
                          </div>
                        </div>

                      </div>
                    ) : (
                      <div className="grey-bar d-flex justify-content-between align-items-center">
                        <p className="text-start jobs-heading card-title h5">Job Description not Available</p>
                        <Button className="btn btn-primary btn-md me-2" onClick={handleOnBackClick}>Revise / Create JD</Button>
                      </div>
                    )}
                  </>
                )}
              </div>
            )}
            {activeTab === 'jd' && selectedJdTab === "task-Automation" && (
              <div className="tab-pane">
                <SbTaskAutomation skillsData={skillsData} jobData={jobData} overallPotential={overallPotential} loadingAP={loadingAP}></SbTaskAutomation>
              </div>
            )}
            {activeTab === 'skills-bridger' && (
              <div className="tab-pane">
                <p>{jobData.skills_bridger || 'Comming Soon..'}</p>
              </div>
            )}
            {activeTab === 'learning-catalyst' && (
              <div className="tab-pane">
                <p>{jobData.learning_catalyst || 'Comming Soon..'}</p>
              </div>
            )}
            {activeTab === 'talent-mobility' && (
              <div className="tab-pane">
                <p>{jobData.talent_mobility || 'Comming Soon..'}</p>
              </div>
            )}
          </div>
        </div>
      </Card.Body>

      <div>

        {showEvalDrawer && <div className="overlay" onClick={handleEvalDrawerToggle}></div>}

        <div className={`drawer eval-pop-up ${showEvalDrawer ? 'open' : ''}`}>
          <div className="drawer-header">
            <h4>JD Evaluation Score</h4>
            <Button variant="close" onClick={handleEvalDrawerToggle}></Button>
          </div>

          <div className="drawer-body">
            {
              ogJdEval["Evaluation_Score"] && showDiff ? (
                <Table>
                  <thead className="jobs-table-header">
                    <tr className="text-body-tertiary text-start text-uppercase jobs-table-heading">
                      <th className="bg-tertiary text-start">Parameter</th>
                      <th className="bg-tertiary">Original JD</th>
                      <th className="bg-tertiary">Generated JD</th>
                    </tr>
                  </thead>
                  <tbody>


                    {loading ? (
                      <tr className="jobs-table-content">
                        <td colSpan="3" className="text-center">
                          <div className="d-flex justify-content-center mb-3">
                            <div className="spinner-border" role="status">
                              <span className="sr-only"></span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ) : (
                      meanOgJdEval.map((item) => {

                        const matchingSbItem = meanSbJdEval.find(sbItem => sbItem.name === item.name);
                        let vsBoxClass = '';

                        if (matchingSbItem) {
                          if (matchingSbItem.value < item.value) {
                            vsBoxClass = 'red-bg';
                          } else if (matchingSbItem.value === item.value) {
                            vsBoxClass = 'blue-bg';
                          } else {
                            vsBoxClass = 'green-bg';
                          }
                          if (matchingSbItem.name == "Evaluation_Score") {
                            vsBoxClass = 'unset-bg'
                          }
                        }

                        return (
                          <tr key={item.name} className="eval-table-content">
                            <td className="eval-table-title">{item.name == "Evaluation_Score" ? <span className="unset-bg">Evaluation Score</span> : changeCase.capitalCase(item.name)}</td>
                            <td>

                              {item.name == "Evaluation_Score" ? <span className="unset-bg text-center">{item.value}%</span> : <span className={`vs-box`}>{item.value} / 5</span>}
                            </td>
                            <td>
                              {matchingSbItem.name == "Evaluation_Score" ?
                                <span className={`text-center ${vsBoxClass}`}>
                                  {matchingSbItem.value + "%"}</span> : <span className={`vs-box ${vsBoxClass}`}>{matchingSbItem.value} / 5</span>
                              }

                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </Table>
              ) : (
                <></>
              )
            }

            {
              sbJdEval["Evaluation_Score"] && !showDiff ? (
                <Table style={{ "width": "25vw" }}>
                  <thead className="jobs-table-header">
                    <tr className="text-body-tertiary text-start text-uppercase jobs-table-heading ">
                      <th className="bg-tertiary  text-start">Parameter</th>
                      <th className="bg-tertiary">Score</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr className="jobs-table-content">
                        <td colSpan="3" className="text-center">
                          Loading...
                        </td>
                      </tr>
                    ) : (
                      meanSbJdEval.map((item) => {

                        return (
                          <tr key={item.name} className="eval-table-content">
                            <td className="eval-table-title">{item.name == "Evaluation_Score" ? <span className="unset-bg">Evaluation Score</span> : changeCase.capitalCase(item.name)}</td>
                            <td>
                              {item.name == "Evaluation_Score" ? <span className="unset-bg text-center">{item.value}%</span> : <span className={`vs-box`}>{item.value} / 5</span>}
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </Table>
              ) : (
                <></>
              )
            }

          </div>
        </div >
      </div >
    </Card >

  );
};

export default SbJobDetails;