import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../State/AuthContext";
import SB_AUTH_PAGE from "../SbAuthPage/SbAuthPage";
import "./HomePage.css";
import { AuthFunctionsProvider } from "../../State/AuthFunctionsContext";
import SB_LAYOUT from "../../Components/SbLayoutComponent/SbLayoutComponent";
import { Provider } from 'react-redux';
import store from '../../Store/store';

const SbHomePage = () => {

  const navigate = useNavigate();
  const { email, setEmail } = useContext(AuthContext);

  const handleSelectChange = (event) => {
    const selectedPath = event.target.value;
    if (selectedPath) navigate(selectedPath);
  };

  return (
    <div className="home-page">
      {!email ? (
        <AuthFunctionsProvider>
          <SB_AUTH_PAGE />
        </AuthFunctionsProvider>

      ) : (
        <SbDashboard email={email} handleSelectChange={handleSelectChange} />
      )}
    </div>
  );
};

// Extracted logged-in view (dashboard) for clarity
const SbDashboard = ({ email, handleSelectChange }) => (
  <>
    <Provider store={store}>
      <SB_LAYOUT />
    </Provider>
  </>
);

export default SbHomePage;
