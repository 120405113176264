import React, { useState, useEffect, useRef } from "react";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./SbTaskAutomation.scss";
import "bootstrap-icons/font/bootstrap-icons.css";


export default function SbTaskAutomation({ skillsData, jobData, overallPotential, loadingAP }) {
    const [openSkillAccordion, setOpenSkillAccordion] = useState(null);

    const selected_role = jobData.job_title;


    const toggleAccordion = (id) => {
        setOpenSkillAccordion(openSkillAccordion === id ? null : id);
    };

    return (
        <Container fluid className="p-4 task-automation">
            {loadingAP ? (
                <div className="d-flex justify-content-center mb-3">
                    <div className="spinner-border" role="status">
                        <span className="sr-only"></span>
                    </div>
                </div>
            ) : (
                <Card className="border-0 rounded-0 shadow-sm">
                    <Card.Body>
                        <Row className="align-items-center">
                            {/* Left Section - Fully Left Aligned */}
                            <Col md={6} className="text-start">
                                <Card.Title className="">Task Automation Potential for {selected_role}</Card.Title>
                                <Card.Text className="text-body-tertiary">A descriptive body text comes here</Card.Text>
                            </Col>

                            {/* Right Section - Fully Right Aligned */}
                            <Col md={6} className="d-flex flex-column align-items-end text-end">

                                <div className="progress-bar-container mt-2">
                                    <div className="progress-bar-custom" style={{ width: `${overallPotential}%` }}>
                                        {overallPotential}%
                                    </div>
                                </div>
                                {/* <div className="d-flex align-items-center">
                                    <p className=".text-body-tertiary avg-augmentation mb-0">
                                        <span className="me-2"> <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect width="9" height="9" rx="1" fill="#0088CE" />
                                        </svg></span>Average Automation Potential</p>
                                </div> */}
                            </Col>
                        </Row>

                    </Card.Body>

                    <div className="table-responsive">
                        <Table className="table">
                            <thead>
                                <tr>
                                    <th md={4} className="table-header skill-name-col bg-tertiary">
                                        TASK
                                    </th>
                                    <th md={4} className="table-header automation-potential-col bg-tertiary">
                                        AUTOMATION POTENTIAL
                                    </th>
                                    <th md={4} className="table-header action-col bg-tertiary"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {skillsData.map((skill, index) => {
                                    const automationPotential = parseInt(skill.automation_potential);

                                    return (
                                        <React.Fragment key={index}>
                                            <tr>
                                                <td className="skill-name">
                                                    <div className="skill-item">
                                                        <span className="skill-text">{skill.accountability}</span>
                                                    </div>
                                                    <div className="text-body-tertiary skill-reason">{skill.reason}</div>
                                                </td>

                                                <td>
                                                    <div className="progress-bar-container">
                                                        <div
                                                            className="progress-bar-custom"
                                                            style={{ width: `${automationPotential}%` }}
                                                        >
                                                            {automationPotential}%
                                                        </div>
                                                    </div>
                                                </td>

                                                <td style={{ textAlign: "right", verticalAlign: "middle" }}>
                                                    <i
                                                        className={`bi ${openSkillAccordion === index ? "bi-caret-up-fill" : "bi-caret-down-fill"}`}
                                                        style={{
                                                            color: "#565E70",
                                                            fontSize: "24px",
                                                            cursor: "pointer",
                                                            transition: "transform 0.3s ease-in-out",
                                                        }}
                                                        onClick={() => toggleAccordion(index)}
                                                    ></i>
                                                </td>
                                            </tr>

                                            {openSkillAccordion === index && (
                                                <tr>
                                                    <td colSpan="3">
                                                        <Table className="mt-3 accordion-table">
                                                            <thead>
                                                                <tr className="accordion-header">
                                                                    <th>What Technology?</th>
                                                                    <th>Which Competitors?</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr className="accordion-row">
                                                                    <td>AI, IoT, Quantum Computing, Blockchain, Augmented Reality</td>
                                                                    <td>Competitor 1, Competitor 2, Competitor 3, Competitor 4, Competitor 5</td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                    </td>
                                                </tr>
                                            )}

                                        </React.Fragment>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </div>
                </Card >
            )
            }

        </Container >
    );
}
