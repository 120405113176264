import React, { useContext } from "react";
import { AuthFunctionsContext } from "../../State/AuthFunctionsContext";

const SbForgotPasswordComponent = () => {
    const {
        username,
        setUsername,
        otp,
        setOtp,
        password,
        setPassword,
        confirmPassword,
        setConfirmPassword,
        error,
        sendOtpToEmail, // Function to send OTP
        verifyOtpAndResetPassword, // Function to verify OTP and reset password
        loadingAuth,
        toggleForgotPassword,
        sb_auth_handleBlur,
        forgotPasswordStep,
        authStatus,
    } = useContext(AuthFunctionsContext);


    const handleEmailSubmit = async (e) => {
        e.preventDefault();
        await sendOtpToEmail(username); // Send OTP to the email
    };

    const handleResetPasswordSubmit = (e) => {
        e.preventDefault();
        verifyOtpAndResetPassword(username, otp, password, confirmPassword);
    };

    return (
        <>
            {forgotPasswordStep === 1 ? (

                // Step 1: Email input to send OTP
                <form onSubmit={handleEmailSubmit}>
                    <div className="mb-3">
                        <label htmlFor="email" className="form-label w-100 text-start">
                            Business Email
                        </label>
                        <input
                            type="email"
                            className="form-control"
                            id="email"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            onBlur={sb_auth_handleBlur}
                            required
                        />
                        {error.username && <div className="error-message-box auth-page-error-box">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M7.99989 0.00488281C3.58265 0.00488281 0.00488281 3.58265 0.00488281 7.99989C0.00488281 12.4171 3.58265 15.9949 7.99989 15.9949C12.4171 15.9949 15.9949 12.4171 15.9949 7.99989C15.9949 3.58265 12.4171 0.00488281 7.99989 0.00488281ZM7.00051 12.9968V10.998H8.99926V12.9968H7.00051ZM7.00051 3.00301V9.99864H8.99926V3.00301H7.00051Z" fill="#D14343" />
                            </svg>
                            <div className="error-message-holder">
                                <p className="error-message">{error.username}</p>
                            </div>
                        </div>}
                    </div>

                    {error.authfail && <div className="error-message-box auth-page-error-box">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M7.99989 0.00488281C3.58265 0.00488281 0.00488281 3.58265 0.00488281 7.99989C0.00488281 12.4171 3.58265 15.9949 7.99989 15.9949C12.4171 15.9949 15.9949 12.4171 15.9949 7.99989C15.9949 3.58265 12.4171 0.00488281 7.99989 0.00488281ZM7.00051 12.9968V10.998H8.99926V12.9968H7.00051ZM7.00051 3.00301V9.99864H8.99926V3.00301H7.00051Z" fill="#D14343" />
                        </svg>
                        <div className="error-message-holder">
                            <p className="error-message">Error</p>
                            <p className="error-message-text" dangerouslySetInnerHTML={{ __html: error.authfail }} />
                        </div>
                    </div>}

                    {loadingAuth ? (
                        <div className="d-flex justify-content-center mb-3">
                            <div className="spinner-border" role="status">
                                <span className="sr-only"></span>
                            </div>
                        </div>
                    ) : (
                        <button type="submit" className="btn btn-primary w-100 btn-lg mb-3 auth-submit-btn">
                            Send OTP
                        </button>
                    )}
                </form>
            ) : (
                // Step 2: OTP and new password input
                <>

                    <div className="info-message-box mb-3 auth-page-info-box">
                        <span>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M8 0C3.58 0 0 3.58 0 8C0 12.42 3.58 16 8 16C12.42 16 16 12.42 16 8C16 3.58 12.42 0 8 0ZM9 3V5H7V3H9ZM6 12V13H10V12H9V6H6V7H7V12H6Z" fill="#008080" />
                            </svg>
                        </span>
                        <div className="info-message-holder">
                            <p className="success-message">Information</p>
                            <p className="info-message-text">If your email adress is registered with us, you will recieve OTP.</p>
                        </div>

                    </div>

                    <form onSubmit={handleResetPasswordSubmit}>
                        <div className="mb-3">
                            <label htmlFor="otp" className="form-label w-100 text-start">
                                Enter OTP
                            </label>
                            <input
                                type="tel"
                                className="form-control"
                                id="otp"
                                value={otp}
                                onChange={(e) => setOtp(e.target.value)}
                                onBlur={sb_auth_handleBlur}
                                maxLength={6}
                                required
                            />
                            {error.otp && <div className="error-message-box auth-page-error-box">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M7.99989 0.00488281C3.58265 0.00488281 0.00488281 3.58265 0.00488281 7.99989C0.00488281 12.4171 3.58265 15.9949 7.99989 15.9949C12.4171 15.9949 15.9949 12.4171 15.9949 7.99989C15.9949 3.58265 12.4171 0.00488281 7.99989 0.00488281ZM7.00051 12.9968V10.998H8.99926V12.9968H7.00051ZM7.00051 3.00301V9.99864H8.99926V3.00301H7.00051Z" fill="#D14343" />
                                </svg>
                                <div className="error-message-holder">
                                    <p className="error-message">{error.otp}</p>
                                </div>
                            </div>}
                        </div>

                        <div className="mb-3">
                            <label htmlFor="password" className="form-label w-100 text-start">
                                New Password
                            </label>
                            <input
                                type="password"
                                className="form-control"
                                id="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                onBlur={sb_auth_handleBlur}
                                required
                            />
                            {error.password && <div className="error-message-box auth-page-error-box">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M7.99989 0.00488281C3.58265 0.00488281 0.00488281 3.58265 0.00488281 7.99989C0.00488281 12.4171 3.58265 15.9949 7.99989 15.9949C12.4171 15.9949 15.9949 12.4171 15.9949 7.99989C15.9949 3.58265 12.4171 0.00488281 7.99989 0.00488281ZM7.00051 12.9968V10.998H8.99926V12.9968H7.00051ZM7.00051 3.00301V9.99864H8.99926V3.00301H7.00051Z" fill="#D14343" />
                                </svg>
                                <div className="error-message-holder">
                                    <p className="error-message">{error.password}</p>
                                </div>
                            </div>}
                        </div>

                        <div className="mb-3">
                            <label htmlFor="confirmPassword" className="form-label w-100 text-start">
                                Confirm New Password
                            </label>
                            <input
                                type="password"
                                className="form-control"
                                id="confirmPassword"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                onBlur={sb_auth_handleBlur}
                                required
                            />
                            {error.confirmPassword && <div className="error-message-box auth-page-error-box">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M7.99989 0.00488281C3.58265 0.00488281 0.00488281 3.58265 0.00488281 7.99989C0.00488281 12.4171 3.58265 15.9949 7.99989 15.9949C12.4171 15.9949 15.9949 12.4171 15.9949 7.99989C15.9949 3.58265 12.4171 0.00488281 7.99989 0.00488281ZM7.00051 12.9968V10.998H8.99926V12.9968H7.00051ZM7.00051 3.00301V9.99864H8.99926V3.00301H7.00051Z" fill="#D14343" />
                                </svg>
                                <div className="error-message-holder">
                                    <p className="error-message">{error.confirmPassword}</p>
                                </div>
                            </div>}
                        </div>

                        {error.authfail && <div className="error-message-box auth-page-error-box">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M7.99989 0.00488281C3.58265 0.00488281 0.00488281 3.58265 0.00488281 7.99989C0.00488281 12.4171 3.58265 15.9949 7.99989 15.9949C12.4171 15.9949 15.9949 12.4171 15.9949 7.99989C15.9949 3.58265 12.4171 0.00488281 7.99989 0.00488281ZM7.00051 12.9968V10.998H8.99926V12.9968H7.00051ZM7.00051 3.00301V9.99864H8.99926V3.00301H7.00051Z" fill="#D14343" />
                            </svg>
                            <div className="error-message-holder">
                                <p className="error-message">Error</p>
                                <p className="error-message-text" dangerouslySetInnerHTML={{ __html: error.authfail }} />
                            </div>
                        </div>}

                        {loadingAuth ? (
                            <div className="d-flex justify-content-center mb-3">
                                <div className="spinner-border" role="status">
                                    <span className="sr-only"></span>
                                </div>
                            </div>
                        ) : (
                            <button type="submit" className="btn btn-primary w-100 btn-lg mb-3">
                                Reset Password
                            </button>
                        )}
                    </form>
                </>
            )}

            <div className="text-start p-3">
                Remember your password?{" "}
                <a onClick={toggleForgotPassword} className="">
                    Go back to Login
                </a>
            </div>
        </>
    );
};

export default SbForgotPasswordComponent;
