import React, { createContext, useState, } from "react";


export const JobDataContext = createContext();

export const JobDataProvider = ({ children }) => {

    const [selectedJob, setSelectedJob] = useState(null);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [selectedJdTab, setSelectedJdTab] = useState('job-description');

    const toggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };

    // Handle the back button click
    const handleBack = (create = false) => {
        setSelectedJob(null);  // Reset selectedJob to go back to the table
        if (create === true) {
            toggleDrawer();
        }
    };

    return (
        <JobDataContext.Provider
            value={{
                selectedJob,
                setSelectedJob,
                isDrawerOpen,
                setIsDrawerOpen,
                toggleDrawer,
                handleBack,
                selectedJdTab,
                setSelectedJdTab
            }}
        >
            {children}
        </JobDataContext.Provider>
    );
};
