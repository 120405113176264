import React from "react";
import SB_SKILLS_BRIDGER from "../../Assets/Images/sb_skills_bridger.png";

const SbSkillsBridger = () => {

  return (
    <div className="skills-Bridger">
      <img src={SB_SKILLS_BRIDGER} width={"100%"}></img>
      </div>
  );
};

export default SbSkillsBridger;
