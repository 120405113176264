import React, { useContext } from "react";
import sb_logo from "../../Assets/Images/sb_logo.png";
import sb_login_bg from '../../Assets/Images/sb_login_bg.png';
import SbLoginComponent from "../../Components/SbLoginComponent/SbLoginComponent";
import SbSignupComponent from "../../Components/SbSignupComponent/SbSignupComponent";
import SbForgotPasswordComponent from "../../Components/SbForgotPswdComponent/SbForgotPswdComponent";
import { AuthFunctionsContext } from '../../State/AuthFunctionsContext';
import "./SbAuthPage.scss"

const SbAuthPage = () => {

  const { isSignup, isForgotPassword } = useContext(AuthFunctionsContext);

  return (
    <div className="container-fluid p-0">
      <div className="row vh-100 g-0">
        <div className="col-sm-6 bg-secondary d-flex align-items-center justify-content-center">
          <div className="text-center" style={{ maxWidth: '630px' }}>
            <img src={sb_login_bg} width="630" className="img-fluid mb-5" alt="Skills Bridge" />
            <div className="auth-heading">BETTER PEOPLE, BIGGER IMPACT</div>
            <div className="mb-2 auth-subheading">Let's work together to shape a workforce equipped for today and tomorrow’s challenges.</div>
          </div>
        </div>
        <div className="col-sm-6 d-flex align-items-center justify-content-center">
          <div className="p-4">
            <div className="p-5 auth-page-img"><img src={sb_logo} alt="Skills Bridge" className="img-fluid mb-4" /></div>
            {isForgotPassword ? (
              <SbForgotPasswordComponent />
            ) : isSignup ? (
              <SbSignupComponent />
            ) : (
              <SbLoginComponent />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SbAuthPage;
