import React, { useContext, useState, useEffect, } from "react";
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { Container, Row, Col, Form, } from 'react-bootstrap';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import graph from "../../Assets/Icons/sb_graph_icon.png";
import { fetchRoles } from "../../Api/apiService";
import { AuthContext } from "../../State/AuthContext";
import "./SbJobsLibrary.scss";
import SbJdCreationDrawer from "../../Components/SbJdCreationDrawer/SbJdCreationDrawer";
import { useSelector, useDispatch } from 'react-redux';
import { markAsRead, clearNotification } from '../../Store/actions/notificationActions';  // Import markAsRead action
import { useNavigate } from 'react-router-dom';
import { JobDataContext } from "../../State/JobsDataContext";

const SbJobsLibrary = () => {
  const { email, orgMetaData, loadingOrgMetaData, subscriptionType } = useContext(AuthContext);
  const [jobData, setJobData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [accessToken, setAccessToken] = useState(localStorage.getItem("access_token"));
  const { isDrawerOpen, setIsDrawerOpen, toggleDrawer } = useContext(JobDataContext);
  const [pageNumber, setPageNumber] = useState('1');
  const [reloadRoles, setReloadRoles] = useState(false);
  const [waitingforResponse, setWaitingforResponse] = useState(false);
  const [llmresponse, setLlmresponse] = useState();
  const [rowClassName, setRowClassName] = useState("jobs-table-content");
  const [uploadJdResponseId, setUploadJdResponseId] = useState('');
  const [createJdResponseId, setCreateJdResponseId] = useState('');
  const { selectedJdTab, setSelectedJdTab } = useContext(JobDataContext);

  setSelectedJdTab('job-description');

  const navigate = useNavigate(); // Hook for navigation

  const dispatch = useDispatch();
  const { notifications } = useSelector(state => state.notification); // Get notifications and unread count

  let jobsLibraryDescription = "A descriptive body text comes here";

  if (orgMetaData?.company_structure) {
    const foundKey = Object.keys(orgMetaData?.company_structure).find(
      key => orgMetaData.company_structure[key]?.ui_elements?.jobs_library_description
    );

    if (foundKey) {
      jobsLibraryDescription =
        orgMetaData.company_structure[foundKey].ui_elements.jobs_library_description;
    }
  }

  useEffect(() => {
    if (reloadRoles && waitingforResponse) {
      setRowClassName("jobs-table-content jd-in-progress-row"); // Blue background for "in progress"
    } else if (reloadRoles && !waitingforResponse && llmresponse === true) {
      setRowClassName("jobs-table-content jd-success-row"); // Green background for success
    } else if (reloadRoles && !waitingforResponse && llmresponse === false) {
      setRowClassName("jobs-table-content no-jd-row"); // Red background for failure
    }
  }, [reloadRoles, waitingforResponse, llmresponse]); // Dependencies array ensures it runs only when any of these states change

  // State to track if a table row was clicked
  const { selectedJob, setSelectedJob } = useContext(JobDataContext);
  const { handleBack } = useContext(JobDataContext);

  // Handle page number change
  const handlePageNumberChange = (e) => {
    setPageNumber(e.target.value); // Update state with the new page number
  };

  // Function to fetch roles
  const sb_jd_fetchRoles = async () => {
    setLoading(true);
    setError(null); // Reset error before each fetch

    try {
      // Send API call
      const fetchRolesResponse = await fetchRoles(email, accessToken);

      if (fetchRolesResponse) {
        setJobData(fetchRolesResponse);
      } else {
        setError('No roles found or invalid response.');
      }
    } catch (err) {
      console.error('Error fetching roles:', err);
      setError('Failed to fetch roles. Please try again.');
    } finally {
      setLoading(false);  // Set loading to false once fetch is done
    }
  };

  // Use useEffect to run the function when the component loads
  useEffect(() => {
    sb_jd_fetchRoles(); // Call the function on component mount
  }, [reloadRoles]);


  // Handle checkbox change
  const handleCheckboxChange = async (event, jobId, currentState) => {
    event.stopPropagation();  // Prevent event propagation to parent (row click)

    try {
      const updatedState = currentState !== true; // Toggle the current state (checked/unchecked)

      // make an API request if necessary
      // await updateCriticalRole(jobId, updatedState, accessToken);

      setJobData(prevJobData =>
        prevJobData.map(job =>
          job.job_id === jobId ? { ...job, critical_role: updatedState } : job
        )
      ); // Update local state to reflect the new critical role value
    } catch (err) {
      console.error('Error updating critical role:', err);
    }
  };


  // Handle row click to select a job
  const handleRowClick = (job) => {
    if (!waitingforResponse && llmresponse && (job.job_id === uploadJdResponseId || job.job_id === createJdResponseId)) {
      setRowClassName("jobs-table-content");
      notifications.forEach(notification => {
        if (!notification.read) {
          dispatch(markAsRead(notification.id)); // Dispatch markAsRead with id
        }

      });
    }

    setSelectedJob(job);
    navigate(`/jobs-library/job-details`);
  };

  // // Handle the back button click
  // const handleBack = (create = false) => {
  //   setSelectedJob(null);  // Reset selectedJob to go back to the table
  //   if (create === true) {
  //     toggleDrawer();
  //   }
  // };

  // const toggleDrawer = () => {
  //   setIsDrawerOpen(!isDrawerOpen);
  // };

  // if (selectedJob !== null) {
  //   // If a job is selected, render the JobDetails component
  //   return <SbJobDetails email={email} accessToken={accessToken} jobData={selectedJob} onBack={handleBack} toggleDrawer={toggleDrawer} />;
  // }

  function convertDateFormat(inputDateString) {
    // Parse the input date string to a Date object
    const date = new Date(inputDateString);

    // Format the date similar to "Feb 13 2025"
    const formattedDate = date.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });

    return formattedDate;
  }

  return (
    <>
      <Card className='border-0 rounded-0 shadow-sm'>
        <div className="d-flex align-items-center justify-content-end">
          <Card.Body>
            <Card.Title className="text-start jobs-heading">Jobs Library</Card.Title>
            <Card.Text className="text-start desc-text mt-2"><span className='text-body-tertiary jobs-description text-start'>{jobsLibraryDescription}</span></Card.Text>
          </Card.Body>

          <Card.Body className="d-flex align-items-center justify-content-end">
            <Card.Text className="mb-0"><small className='text-body-tertiary jobs-description mb-0' style={{ marginRight: '19px' }}>Don't see the role you are looking for?</small></Card.Text>

            <Button className="btn btn-primary btn-md me-2" onClick={toggleDrawer} disabled={waitingforResponse || loadingOrgMetaData}>
              <span style={{ marginRight: '9px' }}><svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M8.24504 5.24691H6.74593V3.7478C6.74593 3.33554 6.40863 2.99824 5.99638 2.99824C5.58412 2.99824 5.24683 3.33554 5.24683 3.7478V5.24691H3.74772C3.33547 5.24691 2.99817 5.58422 2.99817 5.99647C2.99817 6.40873 3.33547 6.74603 3.74772 6.74603H5.24683V8.24515C5.24683 8.65741 5.58412 8.99471 5.99638 8.99471C6.40863 8.99471 6.74593 8.65741 6.74593 8.24515V6.74603H8.24504C8.65729 6.74603 8.99459 6.40873 8.99459 5.99647C8.99459 5.58422 8.65729 5.24691 8.24504 5.24691ZM5.99642 0C2.6834 0 0 2.68342 0 5.99647C0 9.30952 2.6834 11.9929 5.99642 11.9929C9.30945 11.9929 11.9928 9.30952 11.9928 5.99647C11.9928 2.68342 9.30945 0 5.99642 0ZM5.99679 10.4938C3.51577 10.4938 1.49947 8.47751 1.49947 5.99647C1.49947 3.51543 3.51577 1.49912 5.99679 1.49912C8.47781 1.49912 10.4941 3.51543 10.4941 5.99647C10.4941 8.47751 8.48531 10.4938 5.99679 10.4938Z" fill="white" />
              </svg>
              </span>
              Revise / Create JD
            </Button>

          </Card.Body>
        </div>

        <Table>
          <thead className="jobs-table-header">
            <tr className='text-body-tertiary text-start text-uppercase jobs-table-heading'>
              <th className="bg-tertiary">Role Name</th>
              <th className="bg-tertiary">Business</th>
              <th className="bg-tertiary">Location</th>
              <th className="bg-tertiary">Function</th>
              <th className="bg-tertiary">Job Grade</th>
              <th className="bg-tertiary">Total postions</th>
              <th className="bg-tertiary">NO.Vacancies</th>
              <th className="bg-tertiary">JD last modified</th>
              <th className="bg-tertiary">Critical roles</th>
              <th className="bg-tertiary">        </th>
            </tr>
          </thead>
          <tbody>

            {loading ? (
              <tr className="jobs-table-content">
                <td colSpan="9" className="text-center">
                  <div className="d-flex justify-content-center mb-3">
                    <div className="spinner-border" role="status">
                      <span className="sr-only"></span>
                    </div>
                  </div>
                </td>
              </tr>
            ) : (
              jobData.slice(0, 10).map((job, index) => {

                // Determine if the row is in progress (loading)

                const isLoadingRow = job.job_id === uploadJdResponseId || job.job_id === createJdResponseId;

                // Determine if the row is clickable
                const isRowClickable = !(isLoadingRow && waitingforResponse);

                // Set initial values for row title and click action
                let rowTitle = "View Job Description";
                let handleRowClickAction = isRowClickable ? () => handleRowClick(job) : null;

                // Adjust row title and click action based on loading state and LLM response
                if (isLoadingRow && reloadRoles) {
                  if (waitingforResponse) {
                    rowTitle = "Processing";
                  } else if (!llmresponse) {
                    rowTitle = "No JD";
                    // If not clickable and LLM response is false, use the toggleDrawer function
                    handleRowClickAction = () => toggleDrawer();
                  } else {
                    rowTitle = "View Job Description";
                  }
                } else {
                  rowTitle = "View Job Description";
                }

                return (
                  <tr
                    // Apply the class to the first row only
                    className={isLoadingRow ? rowClassName : "jobs-table-content"}
                    key={index}
                    onClick={handleRowClickAction} // Apply conditional click handler
                    title={rowTitle} // Set the title text based on the conditions
                  >
                    <td className="jobs-table-title">{job.job_title}</td>
                    <td>{job.business_unit || '--'}</td>
                    <td>{job.location || '--'}</td>
                    <td>{job.function || ''}</td>
                    <td>{job.level || '--'}</td>
                    <td>{job.total_positions || '--'}</td>
                    <td>{job.vacancies || '--'}</td>
                    <td>{convertDateFormat(job.last_updated_timestamp) || 'N/A'}</td>

                    <td>
                      <div className="jobs-table-critical-checkbox">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          title="Critical role"
                          checked={job.critical_role === true || job.critical_role === "true" || job.critical_role === 'yes'}
                          onClick={(event) => event.stopPropagation()} // Prevent click from reaching parent row
                          onChange={(event) => handleCheckboxChange(event, job.job_id, job.critical_role)} />
                      </div>
                    </td>

                    <td>

                      <Button className="task-automation-potential" variant="white" size="lg" title="Task Automation Potential" onClick={(event) => {
                        event.stopPropagation(); // Always stop propagation to prevent bubbling

                        // Check if the row is clickable
                        if (isRowClickable) {
                          // If the row is clickable, execute the handleRowClick function
                          setSelectedJdTab('task-Automation');
                          handleRowClick(job);
                        } else {
                          // If the row is not clickable, do nothing (prevent any action)
                          return;
                        }
                      }}

                        disabled={subscriptionType === "free"}
                      >
                        <img src={graph} width={37} alt="Task Automation Potential" className="me-2" />
                      </Button>
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </Table>
        <Container>
          <Row>
            <Col className='mb-3'>
              <Row className='align-items-center g-2'>
                <Col xs="auto">Showing</Col>
                <Col xs="auto">
                  <Form.Select aria-label="Default select example" size="sm" className='bg-light'>
                    <option value="1">10</option>
                    <option value="2">20</option>
                    <option value="3">30</option>
                  </Form.Select>
                </Col>
                <Col>Items per page</Col>
              </Row>
            </Col>
            <Col className='mb-3'>
              <Row className='align-items-center justify-content-end g-2'>
                <Col xs="auto">Page</Col>
                <Col xs="auto">
                  <Form.Control size="sm" type="text" value="1" onChange={handlePageNumberChange} style={{ width: 30 }} className='bg-light' />
                </Col>
                <Col xs="auto">of 1</Col>
                <Col xs="auto">
                  <ButtonGroup>
                    <Button variant="secondary">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.6">
                          <path d="M15.41 16.4064L10.83 12L15.41 7.59359L14 6.23999L8 12L14 17.76L15.41 16.4064Z" fill="#8790A4" />
                        </g>
                      </svg>
                    </Button>
                    <Button variant="secondary">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.9">
                          <path d="M8.59 16.4064L13.17 12L8.59 7.59359L10 6.23999L16 12L10 17.76L8.59 16.4064Z" fill="#565E70" />
                        </g>
                      </svg>
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Card>

      {isDrawerOpen && (
        <SbJdCreationDrawer
          email={email}
          accessToken={accessToken}
          isDrawerOpen={isDrawerOpen}
          toggleDrawer={toggleDrawer}
          setReloadRoles={setReloadRoles}
          setWaitingforResponse={setWaitingforResponse}
          setLlmresponse={setLlmresponse}
          setUploadJdResponseId={setUploadJdResponseId}
          setCreateJdResponseId={setCreateJdResponseId}
        />
      )}
    </>
  );
};

export default SbJobsLibrary;
